import React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const MoneyMask = ({ inputRef, onChange, withPrefix = true, ...other }) => {
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: other.name,
            value: Number(values.value) || 0
          }
        });
      }}
      thousandSeparator
      prefix={withPrefix ? '$ ' : ''}
    />
  );
};

export default MoneyMask;

MoneyMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  withPrefix: PropTypes.bool
};
