import {
  downloadExportedRentResume,
  getRentResume
} from 'src/services/incomeService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectRentResumeQueryOptions } from '../selectors/rentResumeSelector';
import { showMessage } from './snackBarActions';

export const LOAD_RENT_RESUME = asyncActionCreator('LOAD_RENT_RESUME');
export const DOWNLOAD_RENT_RESUME = asyncActionCreator('DOWNLOAD_RENT_RESUME');
export const CLEAR_RENT_RESUME = 'CLEAR_RENT_RESUME';
export const SAVE_RENT_RESUME_OPTIONS = 'SAVE_RENT_RESUME_OPTIONS';
export const CLEAR_RENT_RESUME_OPTIONS = 'CLEAR_RENT_RESUME_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectRentResumeQueryOptions(getState());

  const reqOptions = {};
  reqOptions.sortOrder = 'asc';
  reqOptions.sortProperty = 'name';

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (queryOpts.balanceType) {
    reqOptions.balanceType = queryOpts.balanceType;
  }
  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_RENT_RESUME_OPTIONS });
};

export const loadRentResume = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_RENT_RESUME.start });

    dispatch({
      type: SAVE_RENT_RESUME_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);
    const rentResume = await getRentResume(reqOptions);

    return dispatch({
      type: LOAD_RENT_RESUME.success,
      ...rentResume
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_RENT_RESUME.failure });
  }
};

export const downloadExcelRentResume = options => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: DOWNLOAD_RENT_RESUME.start
    });

    dispatch({
      type: SAVE_RENT_RESUME_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState, false);
    await downloadExportedRentResume(reqOptions);

    return dispatch({
      type: DOWNLOAD_RENT_RESUME.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_RENT_RESUME.failure });
  }
};
