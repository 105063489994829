import moment from 'moment';
import AmortizationBalanceType from 'src/utils/constants/accounting';
import {
  CLEAR_AMORTIZATION_BALANCE,
  CLEAR_AMORTIZATION_BALANCE_OPTIONS,
  LOAD_AMORTIZATION_BALANCE,
  SAVE_AMORTIZATION_BALANCE_OPTIONS
} from '../actions/amortizationBalanceActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    amortizationBalanceType: AmortizationBalanceType.POLICY_PRICE,
    periodStartDate: moment()
      .startOf('month')
      .toDate(),
    periodEndDate: moment()
      .endOf('month')
      .toDate()
  },
  policyItems: [],
  amortizationItems: [],
  count: 0
};

const amortizationBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AMORTIZATION_BALANCE.success: {
      return {
        ...state,
        policyItems: action.policyItems,
        amortizationItems: action.amortizationItems,
        count: action.count
      };
    }
    case SAVE_AMORTIZATION_BALANCE_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_AMORTIZATION_BALANCE_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case CLEAR_AMORTIZATION_BALANCE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default amortizationBalanceReducer;
