import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  twelveMonthsRate: Yup.number()
    .min(0, 'SCHEMA_ERRORS.NUMBER_MUST_TO_EQUAL_OR_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.NUMBER_MUST_BE_LESS_THAN_ONE_HUNDRED')
    .required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  eighteenMonthsRate: Yup.number()
    .min(0, 'SCHEMA_ERRORS.NUMBER_MUST_TO_EQUAL_OR_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.NUMBER_MUST_BE_LESS_THAN_ONE_HUNDRED')
    .required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  twentyFourMonthsRate: Yup.number()
    .min(0, 'SCHEMA_ERRORS.NUMBER_MUST_TO_EQUAL_OR_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.NUMBER_MUST_BE_LESS_THAN_ONE_HUNDRED')
    .required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  thirtySixMonthsRate: Yup.number()
    .min(0, 'SCHEMA_ERRORS.NUMBER_MUST_TO_EQUAL_OR_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.NUMBER_MUST_BE_LESS_THAN_ONE_HUNDRED')
    .required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  fortyEigthMonthsRate: Yup.number()
    .min(0, 'SCHEMA_ERRORS.NUMBER_MUST_TO_EQUAL_OR_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.NUMBER_MUST_BE_LESS_THAN_ONE_HUNDRED')
    .required('SCHEMA_ERRORS.VALUE_REQUIRED')
});
