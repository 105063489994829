import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatAmount } from 'src/utils/formatAmout';
import { Link } from 'react-router-dom';
import ViewDocumentButton from 'src/components/ViewDocumentButton/ViewDocumentButton';

const InvestmentRow = ({ id, date, amount, supportDocs, title }) => {
  return (
    <TableRow hover>
      <TableCell align="center">
        <Link to={`/investors/investment/${id}`}>{id}</Link>
      </TableCell>
      <TableCell align="center">{moment(date).format('DD/MM/YYYY')}</TableCell>
      <TableCell align="center">{moment(date).format('MMMM-YYYY')}</TableCell>
      <TableCell align="center">{formatAmount(amount)}</TableCell>
      <TableCell align="center">{`${supportDocs * 100}%`}</TableCell>
      <TableCell align="center">
        <ViewDocumentButton
          documentId={title ? title.id : null}
          documentUrl={title ? title.documentUrl : null}
          disabled={!title}
        />
      </TableCell>
    </TableRow>
  );
};

InvestmentRow.propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  supportDocs: PropTypes.array.isRequired,
  title: PropTypes.object
};

export default InvestmentRow;
