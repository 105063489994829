/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import ResetIcon from '@mui/icons-material/RestartAlt';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import FILTER_TYPES from 'src/utils/constants/filter';
import useStyles from './styles';

const makeInitialState = currentFilterValues => {
  const filterValuesProps = Object.keys(currentFilterValues);
  const initialState = {};

  filterValuesProps.forEach(value => {
    initialState[value] = currentFilterValues[value] || false;
  });

  return initialState;
};

const Filter = ({
  anchorEl,
  handleClose,
  filterValues,
  filters,
  handleChange
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const [state, setState] = useState(makeInitialState(filterValues));
  const { t } = useTranslation();

  const handleChangeFilter = event => {
    if (event.target.type === 'radio') {
      setState({ ...state, [event.target.name]: event.target.value });
      handleChange({ ...state, [event.target.name]: event.target.value });
    } else if (event.target.type === 'checkbox') {
      setState({ ...state, [event.target.name]: event.target.checked });
      handleChange({ ...state, [event.target.name]: event.target.checked });
    }
  };

  const handleChangeDateFilter = useCallback((event, value) => {
    setState({ ...state, [value]: event });
    handleChange({ ...state, [value]: event });
  }, []);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, anchorEl]);

  return (
    <Box className={classes.root}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Container className={classes.horizontalPadding}>
          {filters.map((filter, index) => (
            <Box key={index} display="flex" flexDirection="column">
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typography}
              >
                {t(filter.title).toUpperCase()}
              </Typography>
              {filter.controlGroup === FILTER_TYPES.CHECKBOX &&
                filter.items.map(item => (
                  <FormControlLabel
                    key={item.value}
                    control={
                      <Checkbox
                        name={item.value}
                        color="primary"
                        value={filterValues[item.value]}
                        checked={filterValues[item.value]}
                        onChange={handleChangeFilter}
                      />
                    }
                    label={t(item.label)}
                  />
                ))}

              {filter.controlGroup === FILTER_TYPES.RADIO_BUTTON && (
                <RadioGroup
                  display="flex"
                  flexDirection="column"
                  value={filterValues[filter.value]}
                  onChange={handleChangeFilter}
                  name={filterValues[filter.value]}
                >
                  {filter.items.map(item => (
                    <FormControlLabel
                      key={item.value}
                      control={
                        <Radio
                          name={filter.value}
                          color="primary"
                          checked={filterValues[filter.value] === item.value}
                        />
                      }
                      value={item.value}
                      label={t(item.label)}
                    />
                  ))}
                </RadioGroup>
              )}

              {filter.controlGroup === FILTER_TYPES.DATE &&
                filter.items.map(item => (
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <DatePicker
                      label={t(item.label)}
                      value={filterValues[item.value]}
                      margin="normal"
                      locale="en"
                      views={item.view}
                      minDate={moment().subtract(5, 'years')}
                      onChange={event =>
                        handleChangeDateFilter(event, item.value)
                      }
                      renderInput={params => (
                        <TextField
                          InputProps={{
                            autoComplete: 'off'
                          }}
                          {...params}
                          sx={{ width: '100%', margin: '16px 0' }}
                        />
                      )}
                    />
                    {filterValues[item.value] &&
                      filterValues[item.value] !== moment() && (
                        <IconButton
                          aria-label="reset"
                          size="large"
                          sx={{ alignSelf: 'center' }}
                          onClick={() =>
                            handleChangeDateFilter(null, item.value)
                          }
                        >
                          <ResetIcon />
                        </IconButton>
                      )}
                  </Box>
                ))}
            </Box>
          ))}
        </Container>
      </Popover>
    </Box>
  );
};

Filter.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  filterValues: PropTypes.any,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      controlGroup: PropTypes.string,
      value: PropTypes.string, // Only for radio buttons
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.any,
          view: PropTypes.any // Only for date
        })
      )
    })
  ),
  handleChange: PropTypes.func
};

export default Filter;
