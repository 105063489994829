import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import {
  DaysForFirstRentAfterContractStartList,
  getTermList
} from 'src/utils/constants/distributors';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from '../../initialState';

const StepOne = ({ data, handleNext, isQuoteFromRentCalculator }) => {
  const classes = useStyles();
  const initialState = makeInitialState(data);
  const { t } = useTranslation();
  const { minTerm, maxTerm } = useSelector(
    selectDistributorDetail
  );

  const onNext = useCallback(
    async values => {
      handleNext({ ...data, ...values });
    },
    [handleNext]
  );

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          handleChange,
          handleBlur
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('COMMON.TERM')}
                  value={values.term}
                  name="term"
                  disabled={isQuoteFromRentCalculator}
                  setValue={value => setFieldValue('term', value)}
                  error={Boolean(touched.term && errors.term)}
                  errorText={touched.term && t(errors.term)}
                  list={getTermList(minTerm, maxTerm)}
                />

                <DropdownMenu
                  list={DaysForFirstRentAfterContractStartList}
                  onBlur={handleBlur}
                  label={t('COMMON.DAYS_FOR_FIRST_RENT')}
                  disabled={data.gracePeriod}
                  value={values.gracePeriod}
                  name="gracePeriod"
                  setValue={value => {
                    setFieldValue('gracePeriod', value);
                    setFieldValue(
                      'firstRentDate',
                      moment(values.initialPaymentDate).add(value + 30, 'days')
                    );
                  }}
                  error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                  errorText={touched.gracePeriod && t(errors.gracePeriod)}
                />

                <DatePicker
                  label={t('CONTRACT_VIEW.INITIAL_PAYMENT_DATE')}
                  inputFormat="DD/MM/YYYY"
                  disabled={data.initialPaymentDate}
                  value={values.initialPaymentDate}
                  margin="initialPaymentDate"
                  minDate={moment()}
                  onChange={newValue => {
                    setFieldValue('initialPaymentDate', moment(newValue));
                  }}
                  error={Boolean(
                    touched.initialPaymentDate && errors.initialPaymentDate
                  )}
                  renderInput={params => (
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      {...params}
                      sx={{ width: '100%', mt: 2, mb: 1 }}
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      error={Boolean(
                        touched.initialPaymentDate && errors.initialPaymentDate
                      )}
                      helperText={
                        touched.initialPaymentDate &&
                        t(errors.initialPaymentDate)
                      }
                    />
                  )}
                />

                <DatePicker
                  label={t('CONTRACT_VIEW.FIRST_RENT_DATE')}
                  inputFormat="DD/MM/YYYY"
                  disabled={data.firstRentDate}
                  value={values.firstRentDate}
                  margin="firstRentDate"
                  minDate={
                    values.initialPaymentDate
                      ? values.initialPaymentDate
                      : moment()
                  }
                  onChange={newValue => {
                    setFieldValue('firstRentDate', moment(newValue));
                  }}
                  error={Boolean(touched.firstRentDate && errors.firstRentDate)}
                  renderInput={params => (
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      {...params}
                      sx={{ width: '100%', mt: 2, mb: 1 }}
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      error={Boolean(
                        touched.firstRentDate && errors.firstRentDate
                      )}
                      helperText={
                        touched.firstRentDate && t(errors.firstRentDate)
                      }
                    />
                  )}
                />

                <TextField
                  error={Boolean(touched.moreInfo && errors.moreInfo)}
                  fullWidth
                  helperText={touched.moreInfo && t(errors.moreInfo)}
                  label={t('COMMON.MORE_EQUIPMENT_INFO')}
                  name="moreInfo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  margin="normal"
                  type="text"
                  maxRows={4}
                  value={values.moreInfo}
                  variant="outlined"
                  inputProps={{ maxLength: 120, autoComplete: 'off' }}
                />
              </Box>
              <Box
                className={classes.box}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  isQuoteFromRentCalculator: PropTypes.bool
};

export default StepOne;
