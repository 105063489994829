import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { addPointsPhone } from 'src/utils/common';
import { Gender, PersonType } from 'src/utils/constants/clients';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.tableRoot} component="div">
      <Box sx={{ width: '45%', height: '100%' }}>
        <Box
          component="div"
          sx={{
            width: '100%',
            marginBottom: '1rem'
          }}
        >
          <Typography className={classes.reviewDataSubtitle}>
            {t('BUREAU_FORM.REVIEW_DATA.PERSONAL_DATA')}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('CLIENTS_VIEW.CREATION_FORM.PERSON_TYPE')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t(`CLIENTS_VIEW.CREATION_FORM.${values.personType}`)}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.NAME')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.name}
          </Typography>
        </Box>

        {values.personType === PersonType.PHYSICAL && (
          <>
            <Box component="div" className={classes.reviewBox}>
              <Typography component="div" className={classes.reviewDataLabel}>
                {t('COMMON.PATERNAL_NAME')}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {values.paternalName}
              </Typography>
            </Box>

            <Box component="div" className={classes.reviewBox}>
              <Typography component="div" className={classes.reviewDataLabel}>
                {t('COMMON.MATERNAL_NAME')}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {values.maternalName}
              </Typography>
            </Box>

            <Box component="div" className={classes.reviewBox}>
              <Typography component="div" className={classes.reviewDataLabel}>
                {t('CLIENTS_VIEW.CREATION_FORM.GENDER')}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {values.sex === Gender.MALE
                  ? t('COMMON.MALE')
                  : t('COMMON.FEMALE')}
              </Typography>
            </Box>
          </>
        )}

        {values.personType === PersonType.LEGAL && (
          <Box component="div" className={classes.reviewBox}>
            <Typography component="div" className={classes.reviewDataLabel}>
              {t('CLIENTS_VIEW.CREATION_FORM.DENOMINATION')}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {values.denomination}
            </Typography>
          </Box>
        )}
        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.PHONE')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.phone ? addPointsPhone(values.phone) : '-'}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.EMAIL')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.email}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.RFC')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.rfc}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ width: '45%', height: '100%' }}>
        <Box
          component="div"
          sx={{
            width: '100%',
            marginBottom: '1rem'
          }}
        >
          <Typography className={classes.reviewDataSubtitle}>
            {t('BUREAU_FORM.REVIEW_DATA.CLIENT_ADDRESS')}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.STREET')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.street}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.EXTERNAL_HOME_NUMBER')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.externalHomeNumber}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.INSIDE_HOME_NUMBER')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.insideHomeNumber ?? t('COMMON.NO_DATA')}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.ZIP_CODE')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.zipCode}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.COLONIA')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.colonia}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.CITY')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.city}
          </Typography>
        </Box>

        <Box component="div" className={classes.reviewBox}>
          <Typography component="div" className={classes.reviewDataLabel}>
            {t('COMMON.STATE')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {values.state}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

export default ReviewData;
