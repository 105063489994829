import moment from 'moment';

export default values => ({
  industry: values.industry ?? '',
  birthDate: values.birthDate ?? moment(),
  rfc: values.rfc ?? '',
  deedNumber: values.deedNumber ?? '',
  notaryName: values.notaryName ?? '',
  notaryPracticeNumberAndCity: values.notaryPracticeNumberAndCity ?? '',
  registryRegistrationNumber: values.registryRegistrationNumber ?? ''
});
