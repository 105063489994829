import moment from 'moment';

export default values => ({
  birthState: values.birthState ?? '',
  birthDate: values.birthDate ?? moment(),
  curp: values.curp ?? '',
  rfc: values.rfc ?? '',
  identificationNumber: values.identificationNumber ?? '',
  sex: values.sex || '',
  personType: values.personType || '',
  nationality: values.nationality || ''
});
