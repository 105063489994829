export default values => ({
  ...values,
  phone: values.phone || '',
  email: values.email || '',
  name: values.name || '',
  paternalName: values.paternalName || '',
  maternalName: values.maternalName || '',
  denomination: values.denomination || '',
  sex: values.sex || ''
});
