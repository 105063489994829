import React from 'react';
import moment from 'moment';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PersonType } from 'src/utils/constants/clients';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { selectClientSex } from 'src/utils/common';

const GeneralClientInfo = ({ client }) => {
  const { t } = useTranslation();

  return (
    <>
      {client.personType === PersonType.PHYSICAL ? (
        <>
          <DetailRow name={t('COMMON.FULL_NAME')} value={client.fullName} />

          <DetailRow
            name={t('CLIENTS_VIEW.CREATION_FORM.GENDER')}
            value={selectClientSex(client.sex, t)}
          />

          <DetailRow
            name={t('COMMON.DATE_OF_BIRTH')}
            value={
              client.birthDate
                ? selectFormatDate(client.birthDate, dateFormatType.DAY)
                : t('COMMON.NO_DATA')
            }
          />

          <DetailRow
            name={t('COMMON.AGE')}
            value={client.age || t('COMMON.NO_DATA')}
          />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.BIRTH_STATE')}
            value={client.birthState || t('COMMON.NO_DATA')}
          />

          <DetailRow name="RFC" value={client.rfc} />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.IDENTIFICATION_NUMBER')}
            value={client.identificationNumber}
          />

          <DetailRow name="CURP" value={client.curp || t('COMMON.NO_DATA')} />

          <DetailRow
            name={t('COMMON.COUNTRY_OF_BIRTH')}
            value={t(`COUNTRIES.${client.nationality}`)}
          />
        </>
      ) : (
        <>
          <DetailRow
            name={t('COMMON.FULL_NAME')}
            value={client.fullName || t('COMMON.NO_DATA')}
          />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.INDUSTRY')}
            value={client.industry || t('COMMON.NO_DATA')}
          />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.CONSTITUTION_DATE')}
            value={
              client.birthDate
                ? moment(client.birthDate).format('DD-MMM-YY')
                : t('COMMON.NO_DATA')
            }
          />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.OPERATING_YEARS')}
            value={client.age}
          />

          <DetailRow name="RFC" value={client.rfc || t('COMMON.NO_DATA')} />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.DEED_NUMBER')}
            value={client.deedNumber || t('COMMON.NO_DATA')}
          />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.NOTARY_NAME')}
            value={client.notaryName || t('COMMON.NO_DATA')}
          />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.NOTARY_PRACTICE_NUMBER_AND_CITY')}
            value={client.notaryPracticeNumberAndCity || t('COMMON.NO_DATA')}
          />

          <DetailRow
            name={t('CLIENTS_VIEW.CLIENT_DETAIL.REGISTRY_REGISTRATION_NUMBER')}
            value={client.registryRegistrationNumber || t('COMMON.NO_DATA')}
          />
        </>
      )}
    </>
  );
};

GeneralClientInfo.propTypes = {
  client: PropTypes.object.isRequired
};
export default GeneralClientInfo;
