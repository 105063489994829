import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';

import {
  CLEAR_RENT_RESUME,
  downloadExcelRentResume,
  loadRentResume
} from 'src/store/actions/rentResumeActions';
import useDebounce from 'src/hooks/useDebounce';
import { selectRentResumeQueryOptions } from 'src/store/selectors/rentResumeSelector';
import DownloadButton from 'src/components/DownloadButton';
import SearchBar from 'src/components/SearchBar';
import { RentResumeBalanceType } from 'src/utils/constants/accounting';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectRentResumeQueryOptions);
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce(
    (name, queryOpts) => dispatch(loadRentResume({ ...queryOpts, name })),
    400
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelRentResume());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadRentResume({ balanceType: RentResumeBalanceType.CONTRACT }));
    return () => dispatch({ type: CLEAR_RENT_RESUME });
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { lg: 'row', sm: 'row', xs: 'column' }
          }}
        >
          <Typography sx={{ fontWeight: '400' }} color="primary" variant="h1">
            {t('NAVIGATION.PERIOD_INCOME')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar
              handleSearch={handleSearch}
              searchValue={searchValue}
              placeholder={t('RENT_RESUME.SEARCH_PLACEHOLDER')}
            />

            <DownloadButton onDownload={handleExcelExport} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
