import moment from 'moment';
import dateFormatType from './dateFormatType';
import { capitalizeFirstLetter } from './common';

const selectFormatDate = (date, format = dateFormatType.DAY) => {
  switch (format) {
    case dateFormatType.DAY:
      return moment(date).format('DD-MMM-YY');
    case dateFormatType.MONTH_AND_YEAR:
      return moment(date).format('MMM - YY');
    case dateFormatType.MONTH:
      return capitalizeFirstLetter(moment(date).format('MMM-YY'));
    case dateFormatType.QUARTER:
      return `${capitalizeFirstLetter(
        moment(date)
          .startOf('quarter')
          .format('MMM-YY')
      )} - ${capitalizeFirstLetter(
        moment(date)
          .endOf('quarter')
          .format('MMM-YY')
      )}`;
    case dateFormatType.YEAR:
      return moment(date).format('YYYY');
    case dateFormatType.ONLY_DAY:
      return moment(date).format('DD');
    case dateFormatType.DATE_COMPLETE:
      return moment(date).format('DD-MMM-YY HH:mm:ss');
    default:
      return moment(date).format('DD-MMM-YY');
  }
};

export default selectFormatDate;
