import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import FilterIcon from '@mui/icons-material/FilterList';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  downloadExcelAmortizationBalance,
  loadAmortizationBalance
} from 'src/store/actions/amortizationBalanceActions';
import DownloadButton from 'src/components/DownloadButton';
import TimePeriod from 'src/utils/TimePeriod';
import useDebounce from 'src/hooks/useDebounce';
import { selectAmortizationBalancesQueryOptions } from 'src/store/selectors/amortizationBalanceSelectors';
import moment from 'moment';
import SearchBar from 'src/components/SearchBar';
import Filter from 'src/components/Filter/Filter';
import useStyles from './styles';

const filters = [
  {
    title: 'COMMON.BY_DATE',
    controlGroup: 'radio-button',
    value: 'timePeriod',
    items: [
      {
        label: 'COMMON.THIS_MONTH',
        value: TimePeriod.MONTH
      },
      {
        label: 'COMMON.THIS_QUARTER',
        value: TimePeriod.QUARTER
      },
      {
        label: 'COMMON.THIS_YEAR',
        value: TimePeriod.YEAR
      }
    ]
  }
];

const getFilterOptions = options => {
  if (options.timePeriod === TimePeriod.MONTH) {
    return {
      periodStartDate: moment()
        .startOf('month')
        .toDate(),
      periodEndDate: moment()
        .endOf('month')
        .toDate()
    };
  }

  if (options.timePeriod === TimePeriod.QUARTER) {
    return {
      periodStartDate: moment()
        .quarter(moment().quarter())
        .startOf('quarter')
        .toDate(),
      periodEndDate: moment()
        .endOf('quarter')
        .toDate()
    };
  }

  if (options.timePeriod === TimePeriod.YEAR) {
    return {
      periodStartDate: moment()
        .startOf('year')
        .toDate(),
      periodEndDate: moment()
        .endOf('year')
        .toDate()
    };
  }

  return {};
};

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const queryOptions = useSelector(selectAmortizationBalancesQueryOptions);
  const [filterValues, setFilterValues] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce(
    (equipment, queryOpts) =>
      dispatch(loadAmortizationBalance({ ...queryOpts, equipment })),
    400
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchValue, {
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event)
      });
    },
    [
      setFilterValues,
      searchValue,
      debounceSearch,
      queryOptions,
      getFilterOptions
    ]
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelAmortizationBalance());
  }, [dispatch]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }} {...rest}>
        <Breadcrumb />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { lg: 'row', sm: 'row', xs: 'column' }
          }}
        >
          <Typography sx={{ fontWeight: '400' }} color="primary" variant="h1">
            {t('NAVIGATION.AMORTIZATION_BALANCE')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar
              handleSearch={handleSearch}
              searchValue={searchValue}
              placeholder={t('AMORTIZATION_BALANCE.SEARCH_PLACEHOLDER')}
            />
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding
              )}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterIcon className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>

            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
            <DownloadButton onDownload={handleExcelExport} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
