import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';

const GeneralEquipmentInfo = ({ equipment }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow name={t('ID')} value={equipment.id} />

      <DetailRow
        name={t('COMMON.DISTRIBUTOR')}
        value={
          equipment.distributorModel ? (
            <Link
              to={`/distributors/business/${equipment.distributorModel.id}`}
            >
              {equipment.distributorModel.name}
            </Link>
          ) : (
            equipment.distributor
          )
        }
      />

      <DetailRow
        name={t('COMMON.BRAND')}
        value={equipment.brand || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.MODEL')}
        value={equipment.model || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.YEAR')}
        value={equipment.year || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('EQUIPMENT_VIEW.EQUIPMENT_TYPE')}
        value={
          equipment.equipmentType
            ? equipment.equipmentType.name
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('EQUIPMENT_VIEW.SERIAL_NUMBER')}
        value={equipment.serialNumber || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('EQUIPMENT_VIEW.PURCHASE_DATE')}
        value={
          equipment.purchaseDate
            ? moment(equipment.purchaseDate).format('DD-MMM-YYYY')
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('EQUIPMENT_VIEW.MONTHS_OF_LIFE')}
        value={
          equipment.purchaseDate
            ? moment().diff(moment(equipment.purchaseDate), 'months')
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('COMMON.MORE_INFO')}
        value={equipment.moreInfo || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED')}
        value={equipment.isInsuranceIncluded ? t('COMMON.YES') : t('COMMON.NO')}
      />
    </>
  );
};

GeneralEquipmentInfo.propTypes = {
  equipment: PropTypes.object.isRequired
};
export default GeneralEquipmentInfo;
