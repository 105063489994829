import { GracePeriodValues } from './constants/distributors';
import { TransactionsCategory } from './constants/transactions';
import { getEquipmentDescription } from './equipments';

// eslint-disable-next-line import/prefer-default-export
export const getContractDescription = contract => {
  return `Folio ${contract.folio} - ${getEquipmentDescription(
    contract.equipment
  )}`;
};

export const calculateFirstRentDateFromGracePeriod = (
  startDate,
  gracePeriod
) => {
  switch (gracePeriod) {
    case GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS:
      return startDate;
    case GracePeriodValues.ZERO:
      return startDate.add(1, 'months');
    case GracePeriodValues.THIRTY:
      return startDate.add(2, 'months');
    case GracePeriodValues.SIXTY:
      return startDate.add(3, 'months');
    case GracePeriodValues.NINETY:
      return startDate.add(4, 'months');
    default:
      return startDate;
  }
};

export const selectAmountFromCategory = (category, contract) => {
  switch (category) {
    case TransactionsCategory.RENT:
      return contract.rent;
    case TransactionsCategory.UPFRONT_RENT:
      return contract.rent;
    case TransactionsCategory.INITIAL_PAYMENT:
      return contract.initialPayment;
    case TransactionsCategory.RESIDUAL:
      return contract.residualPayment;
    default:
      return 0;
  }
};
