import { hasAddressCompleted } from 'src/utils/common';

const makeInitialState = values => {
  return {
    quoteId: null,
    startDate: values.initialPaymentDate || null,
    firstRentDate: values.firstRentDate || null,
    endDate: null,
    term: values ? values.term : null,
    initialPayment: values ? values.initialPayment : null,
    sendToDigitalSign: false,
    equipmentLocation: {
      street: values.client?.street,
      insideHomeNumber: values.client?.insideHomeNumber,
      externalHomeNumber: values.client?.externalHomeNumber,
      colonia: values.client?.colonia,
      city: values.client?.city,
      state: values.client?.state,
      zipCode: values.client?.zipCode
    },
    isExistingAddress: hasAddressCompleted(values.client),
    clientId: values ? values.client?.id : null,
    clientPersonType: values ? values.client?.personType : null,
    bankInformation:
      values &&
      values.client?.bankName &&
      (values.client?.bankAccount || values.client?.clabe)
        ? {
            bankName: values.client?.bankName,
            bankAccount: values.client?.bankAccount,
            clabe: values.client?.clabe
          }
        : {},
    gracePeriod: Number(values.gracePeriod) || null,
    isAutomaticPayment: Boolean(values.isAutomaticPayment) || false,
    isQuoteAssignedToClient: Boolean(values.client?.id) || false
  };
};

export default makeInitialState;
