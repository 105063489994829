export default values => ({
  ...values,
  rfc: values.rfc || '',
  curp: values.curp || '',
  street: values ? values.street ?? '' : '',
  insideHomeNumber: values ? values.insideHomeNumber ?? '' : '',
  externalHomeNumber: values ? values.externalHomeNumber ?? '' : '',
  colonia: values ? values.colonia : '',
  city: values ? values.city : '',
  state: values ? values.state : '',
  zipCode: values ? values.zipCode ?? '' : '',
  identificationNumber: values ? values.identificationNumber ?? '' : '',
});
