import { PaymentInputOption } from "src/utils/constants/quotes";

const makeInitialState = values => {
  return {
    client: values ? values.client : '',
    distributor: values ? values.distributor : '',
    distributorEquipment: values ? values.distributorEquipment : '',
    price: values ? values.price : '',
    distributorProfile: values ? values.distributorProfile : '',
    initialPayment: values ? values.initialPayment : 0,
    gracePeriod: values ? values.gracePeriod : '',
    paymentInputOption: values ? values.paymentInputOption : PaymentInputOption.PERCENTAGE
  };
};

export default makeInitialState;
