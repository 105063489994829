import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const getClients = serviceBase({
  request: options =>
    axiosClient.get('/clients', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const downloadExportedClients = serviceBase({
  request: options =>
    axiosClient.get('/clients/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Clientes.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});

export const createNewClient = serviceBase({
  request: client => axiosClient.post(`/clients`, client)
});

export const getClientById = serviceBase({
  request: id => axiosClient.get(`/clients/${id}`),
  retry: 0
});

export const getClientFullNameById = serviceBase({
  request: id => axiosClient.get(`/clients/name/${id}`),
  retry: 0
});

export const updateClientById = serviceBase({
  request: ({ id, client }) => axiosClient.put(`/clients/${id}`, client)
});

export const updateAvalById = serviceBase({
  request: ({ id, aval }) => axiosClient.put(`/clients/aval/${id}`, aval)
});

export const createNewAval = serviceBase({
  request: aval => axiosClient.post('/clients/aval', aval)
});

export const deleteAvalById = serviceBase({
  request: id => axiosClient.delete(`/clients/aval/${id}`)
});

export const getClientAdministratives = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/clients/${id}/administrative`, { params: options }),
  retry: 0
});

export const createNewAdministrative = serviceBase({
  request: administrative =>
    axiosClient.post('/clients/administrative', administrative)
});

export const updateAdministrativeById = serviceBase({
  request: ({ id, administrative }) =>
    axiosClient.patch(`/clients/administrative/${id}`, administrative)
});

export const deleteAdministrativeById = serviceBase({
  request: id => axiosClient.delete(`/clients/administrative/${id}`)
});

export const getAddress = serviceBase({
  request: zipCode => axiosClient.get(`/clients/address?zipCode=${zipCode}`)
});

export const getDocuments = serviceBase({
  request: documents =>
    axiosClient.get(`/document`, {
      params: documents,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const createOrUpdateDocumentById = serviceBase({
  request: document => axiosClient.post(`/document`, document)
});

export const sendClientToValidateById = serviceBase({
  request: id => axiosClient.post('/nufi-validation-score/client', id)
});

export const sendDistributorToValidateById = serviceBase({
  request: id => axiosClient.post(`/nufi-validation-score/distributor/${id}`)
});

export const sendToValidateBillingById = serviceBase({
  request: id => axiosClient.post(`/clients/billing/validation/${id}`)
});
export default getClients;

export const sendMoffinAuthorizationMessageById = serviceBase({
  request: id => axiosClient.post('/credit-profile/send-authorization-form', id)
});

export const getSatWsDetail = serviceBase({
  request: id => axiosClient.get(`/clients/sat-ws/${id}`)
});

export const sendSatWsCredentials = serviceBase({
  request: body => axiosClient.post('/clients/sat-ws/credential', body)
});

export const getSatWsHistory = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/clients/sat-ws/${id}/history`, {
      params: options
    }),
  retry: 0
});

export const updateClientInformationWhitSatWs = serviceBase({
  request: id => axiosClient.post(`/clients/sat-ws/${id}`)
});

export const getAvalsByClientId = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/clients/${id}/avals`, {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const getAvalDetail = serviceBase({
  request: id => axiosClient.get(`/clients/aval/${id}`)
});

export const getIdentityValidationsByClientId = serviceBase({
  request: id => axiosClient.get(`/clients/${id}/identity-validation`)
});

export const getIdentityValidationsByAvalId = serviceBase({
  request: id => axiosClient.get(`/clients/aval/${id}/identity-validation`)
});

export const getBuroValidationsByClientId = serviceBase({
  request: id => axiosClient.get(`/clients/${id}/buro-validation`)
});

export const getBuroValidationsByAvalId = serviceBase({
  request: id => axiosClient.get(`/clients/aval/${id}/buro-validation`)
});

export const searchAvalByRfc = serviceBase({
  request: rfc => axiosClient.get(`/clients/aval/${rfc}/search`)
});

export const createClientFromAval = serviceBase({
  request: values => axiosClient.post(`/clients/create-from-aval`, values)
});

export const searchClientByRfc = serviceBase({
  request: rfc => axiosClient.get(`/clients/${rfc}/search`)
});

export const createAvalFromClient = serviceBase({
  request: values =>
    axiosClient.post(`/clients/aval/create-from-client`, values)
});

export const assignDefaultLegalRepresentative = serviceBase({
  request: id =>
    axiosClient.patch(`/clients/aval/${id}/default-legal-representative`)
});

export const getClientBankAccountInformation = serviceBase({
  request: id => axiosClient.get(`/clients/${id}/bank-information`)
});

export const getClientBuroValidationHistory = serviceBase({
  request: ({ id }) =>
    axiosClient.get(`/clients/${id}/buro-validation/history`),
  retry: 0
});

export const getAvalBuroValidationHistory = serviceBase({
  request: ({ id }) =>
    axiosClient.get(`/clients/aval/${id}/buro-validation/history`),
  retry: 0
});

export const changeClientStatusById = serviceBase({
  request: ({ id, status }) =>
    axiosClient.patch(`/clients/${id}/status`, { status })
});
