import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { submitBureauForm } from 'src/store/actions/bureauFormActions';
import { useDispatch } from 'react-redux';
import FormStepper from 'src/components/FormStepper/FormStepper';
import makeInitialState from './initialState';
import CurrentStep from './CurrentStep';

const stepperLabels = [
  'BUREAU_FORM.STEPS.RFC',
  'BUREAU_FORM.STEPS.CONTACT_INFORMATION',
  'BUREAU_FORM.STEPS.BUREAU_INFORMATION',
  'BUREAU_FORM.STEPS.REVIEW',
  'BUREAU_FORM.STEPS.CONFIRMATION'
];

const BureauForm = () => {
  const initialState = makeInitialState();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const [labels, setLabels] = useState(stepperLabels);

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, []);

  useEffect(() => {
    setLabels(stepperLabels);
  }, [data]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleBack = useCallback(() => {
    switch (step) {
      case 0:
        setData(initialState);
        break;
      case 1:
        setData({ ...initialState, rfc: data.rfc });
        setStep(step - 1);
        break;
      case 2: {
        if (data.existingRfc) {
          setStep(0);
          setData({
            ...initialState,
            rfc: data.rfc,
            name: '',
            maternalName: '',
            paternalName: '',
            email: '',
            phone: ''
          });
          break;
        }
        setStep(step - 1);
        break;
      }
      default:
        setStep(step - 1);
        break;
    }
  }, [step, setStep]);

  const handleSubmit = useCallback(async values => {
    dispatch(submitBureauForm({ ...values }, handleNext));
  });

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingY: '20px'
      }}
    >
      <FormStepper index={step} labels={labels} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'center' },
          width: '80%',
          // minHeight: '70vh'
          minHeight: { md: '70vh', xs: 'auto' }
        }}
      >
        <CurrentStep
          activeStep={step}
          setStep={setStep}
          data={data}
          onNext={handleNext}
          onBack={handleBack}
          onSubmit={handleSubmit}
        />
      </Box>
    </Paper>
  );
};

export default BureauForm;
