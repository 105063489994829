import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import { DocumentType } from 'src/utils/constants/clients';
import DocumentsMenu from 'src/components/DocumentsMenu/DocumentsMenu';
import { Box } from '@mui/material';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import ContractStatus, {
  AmendingAgreementSignStatus,
  hasContractDigitalPromissoryNote,
  hasContractDigitalSign
} from 'src/utils/constants/contracts';
import useStyles from '../../styles';

const getPromissoryNoteSignStatus = contract => {
  if (
    !contract.promissoryNote.physicalSignedDoc &&
    contract.status === ContractStatus.IN_PROGRESS
  ) {
    return (
      <StatusIcon
        description="COMMON.NO_DATA"
        type={IconStatusAndColor.NONE.status}
      />
    );
  }

  switch (contract.status) {
    case ContractStatus.IN_PROGRESS:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );
    case ContractStatus.PENDING_SIGNATURE:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case ContractStatus.SIGNED:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );
    case ContractStatus.DRAFT:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
    default:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};

const getContractSignedStatus = contract => {
  if (!contract.contractDoc)
    return (
      <StatusIcon
        description="COMMON.NO_DATA"
        type={IconStatusAndColor.NONE.status}
      />
    );
  switch (contract.status) {
    case ContractStatus.IN_PROGRESS:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );

    case ContractStatus.PENDING_SIGNATURE:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case ContractStatus.SIGNED:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );
    case ContractStatus.DRAFT:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
    default:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};

const getAmendingAgreementSignStatus = contract => {
  switch (contract.lastCapitalContribution.signStatus) {
    case AmendingAgreementSignStatus.PENDING_SIGNATURE:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case AmendingAgreementSignStatus.SIGNED:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );
    default:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};

const DocumentDetail = ({ contract }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <DetailRow
        name={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box component="div" className={classes.title}>
              {t('CONTRACT_VIEW.CONTRACT_PDF')}
            </Box>
            <Box sx={{ marginLeft: 1 }}>
              {getContractSignedStatus(contract)}
            </Box>
          </Box>
        }
        value={
          <DocumentLink
            link={
              contract.contractDoc ? contract.contractDoc.documentUrl : null
            }
            fileName={
              `Contrato ${contract.clientName}, folio ${contract.folio}` ?? null
            }
          />
        }
      >
        {!hasContractDigitalSign(contract) && (
          <DocumentsMenu
            documentType={DocumentType.CONTRACT}
            contractId={contract.id}
            documents={Boolean(contract.contractDoc)}
          />
        )}
      </DetailRow>
      {contract.promissoryNote.virtualSignedPdf && (
        <>
          <DetailRow
            name={
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box component="div" className={classes.title}>
                  {t('CONTRACT_VIEW.VIRTUAL_PROMISSORY_NOTE')}
                </Box>
                {(contract.status === ContractStatus.PENDING_SIGNATURE ||
                  contract.status === ContractStatus.DRAFT ||
                  hasContractDigitalPromissoryNote(contract)) && (
                  <Box sx={{ marginLeft: 1 }}>
                    {getPromissoryNoteSignStatus(contract)}
                  </Box>
                )}
              </Box>
            }
            value={
              <DocumentLink link={contract.promissoryNote.virtualSignedPdf} />
            }
          />
        </>
      )}
      {contract.promissoryNote.physicalSignedDoc && (
        <>
          <DetailRow
            name={
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box component="div" className={classes.title}>
                  {t('CONTRACT_VIEW.PROMISSORY_NOTE')}
                </Box>
                {contract.status === ContractStatus.IN_PROGRESS &&
                  !hasContractDigitalPromissoryNote(contract) && (
                    <Box sx={{ marginLeft: 1 }}>
                      {getPromissoryNoteSignStatus(contract)}
                    </Box>
                  )}
              </Box>
            }
            value={
              <DocumentLink
                link={
                  contract.promissoryNote.physicalSignedDoc
                    ? contract.promissoryNote.physicalSignedDoc.documentUrl
                    : null
                }
              />
            }
          >
            <DocumentsMenu
              contractId={contract.id}
              documentType={DocumentType.PROMISSORY_NOTE}
              promissoryNoteId={contract.promissoryNote.id ?? null}
              documents={Boolean(contract.promissoryNote.physicalSignedDoc)}
            />
          </DetailRow>
        </>
      )}
      {contract.lastCapitalContribution && (
        <DetailRow
          name={
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box component="div" className={classes.title}>
                {t('COMMON.AMENDING_AGREEMENT')}
              </Box>
              <Box sx={{ marginLeft: 1 }}>
                {getAmendingAgreementSignStatus(contract)}
              </Box>
            </Box>
          }
          value={
            <DocumentLink
              link={
                contract.lastCapitalContribution.document &&
                contract.lastCapitalContribution.document.documentUrl
              }
            />
          }
        >
          <DocumentsMenu
            contractId={contract.id}
            documentType={DocumentType.AMENDING_AGREEMENT}
            loadAmendingAgreements
            documents={contract.lastCapitalContribution.document}
            isDisabledAdd
          />
        </DetailRow>
      )}
    </>
  );
};

DocumentDetail.propTypes = {
  contract: PropTypes.object.isRequired
};

export default DocumentDetail;
