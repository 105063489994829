const makeInitialState = () => {
  return {
    rfc: '',
    name: '',
    maternalName: '',
    paternalName: '',
    phone: '',
    email: '',
    personType: '',
    denomination: '',
    industry: '',
    identificationNumber: '',
    sex: '',
    nip: '',
    curp: ''
  };
};

export default makeInitialState;
