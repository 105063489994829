import React, { useEffect, useState } from 'react';
import { Typography, Breadcrumbs, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { isNumericString } from 'src/utils/common';
import PropTypes from 'prop-types';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import { useSelector } from 'react-redux';
import UserType from 'src/utils/constants/auth';

const useStyles = makeStyles(() => ({
  breadcrumbsContainer: {
    marginBottom: 20,
    display: 'flex',
    gap: 5
  },
  breadcrumbsItems: {
    fontSize: 12,
    letterSpacing: 0.4,
    textTransform: 'capitalize'
  }
}));

const Breadcrumb = ({ doNotRedirectIndexList = [], stringCrumb = '' }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = window.location.pathname;
  const system = useSelector(selectAuthSystem);
  const [labelCrumb, setLabelCrumb] = useState('');

  let currentLink = '';

  const pathNotToShow = ['user', 'super-admin'];

  useEffect(() => {
    setLabelCrumb(stringCrumb);
  }, [stringCrumb]);

  const crumbs = location
    .split('/')
    .filter(crumb => crumb !== '' && !pathNotToShow.includes(crumb))
    .map((crumb, index) => {
      currentLink += `/${crumb}`;
      if (doNotRedirectIndexList.includes(index)) {
        return (
          <Typography key={crumb} className={classes.breadcrumbsItems}>
            {isNumericString(crumb)
              ? labelCrumb || crumb
              : t(`BREADCRUMBS.${crumb.toUpperCase()}`)}
          </Typography>
        );
      }
      return (
        <Link
          key={crumb}
          href={
            system === UserType.DISTRIBUTOR
              ? `/user${currentLink}`
              : currentLink
          }
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Typography className={classes.breadcrumbsItems}>
            {isNumericString(crumb)
              ? labelCrumb || crumb
              : t(`BREADCRUMBS.${crumb.toUpperCase()}`)}
          </Typography>
        </Link>
      );
    });

  return (
    <div className={classes.breadcrumbsContainer}>
      <Breadcrumbs aria-label="breadcrumb">{crumbs}</Breadcrumbs>
      {crumbs.length <= 1 && '/'}
    </div>
  );
};

export default Breadcrumb;

Breadcrumb.propTypes = {
  doNotRedirectIndexList: PropTypes.array,
  stringCrumb: PropTypes.string
};
