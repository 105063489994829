import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  rfc: Yup.string()
    .matches(
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
    )
    .required('SCHEMA_ERRORS.RFC_REQUIRED'),
  curp: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string()
      .matches(
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_FORMAT_INVALID'
      )
      .required('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CURP_REQUIRED')
  }),
  sex: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.GENDER_REQUIRED')
  }),
  identificationNumber: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string()
      .max(30)
      .required(
        'SCHEMA_ERRORS.CLIENT_DETAIL_FORM.IDENTIFICATION_NUMBER_REQUIRED'
      )
  }),

  zipCode: Yup.string()
    .matches(/^[0-9]{5}$/, 'SCHEMA_ERRORS.ZIP_CODE_INVALID')
    .required('SCHEMA_ERRORS.ZIP_CODE_REQUIRED'),
  street: Yup.string().required('SCHEMA_ERRORS.STREET_REQUIRED'),
  insideHomeNumber: Yup.string().nullable(),
  externalHomeNumber: Yup.string().required(
    'SCHEMA_ERRORS.EXTERNAL_HOME_NUMBER_REQUIRED'
  ),
  colonia: Yup.string(),
  city: Yup.string().required('SCHEMA_ERRORS.CITY_REQUIRED'),
  state: Yup.string().required('SCHEMA_ERRORS.STATE_REQUIRED')
});
