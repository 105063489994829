import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DownloadButton from 'src/components/DownloadButton';
import { FilterList } from '@mui/icons-material';
import Filter from 'src/components/Filter/Filter';
import FILTER_TYPES from 'src/utils/constants/filter';
import selectFormatDate from 'src/utils/selectDateFormat';
import moment from 'moment';
import dateFormatType from 'src/utils/dateFormatType';
import {
  selectTotalUpfrontRents,
  selectUpfrontRentsQueryOptions
} from 'src/store/selectors/upfrontRentsSelector';
import {
  exportUpfrontRentList,
  loadUpfrontRents
} from 'src/store/actions/upfrontRentsActions';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import useStyles from '../styles';

const filters = [
  {
    title: 'COMMON.DATE',
    controlGroup: FILTER_TYPES.DATE,
    items: [
      {
        label: 'COMMON.DATE',
        value: 'date',
        view: ['year', 'month']
      }
    ]
  }
];

function getFilterOptions(options) {
  let date = null;

  if (options.date) {
    date = moment(options.date).toDate();
  }

  return { date };
}

const Toolbar = ({ className, ...rest }) => {
  const [filterValues, setFilterValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectUpfrontRentsQueryOptions);
  const totalUpfrontRents = useSelector(selectTotalUpfrontRents);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      if (event.date && moment(event.date).isValid()) {
        setFilterValues(event);
        dispatch(
          loadUpfrontRents({
            ...getFilterOptions(event),
            page: 0
          })
        );
      }
    },
    [setFilterValues, queryOptions, getFilterOptions]
  );

  const selectDateToShow = () => {
    if (filterValues.date) {
      return selectFormatDate(filterValues.date, dateFormatType.MONTH_AND_YEAR);
    }
    return selectFormatDate(moment(), dateFormatType.MONTH_AND_YEAR);
  };

  const handleUpfrontRentsListExport = () => {
    dispatch(exportUpfrontRentList());
  };

  useEffect(() => {
    dispatch(
      loadUpfrontRents({
        ...queryOptions,
        date: moment().toDate()
      })
    );
  }, []);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{ fontWeight: '400', marginRight: 1 }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.UPFRONT_RENTS')}
            </Typography>
            <Box
              sx={{
                background: '#ebf6ec',
                borderRadius: '10%',
                padding: '5px 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                style={{ fontWeight: '400' }}
                color="primary"
                variant="h2"
              >
                {`$ ${formatAmountWithDecimals(totalUpfrontRents, 2)}`}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              color="primary"
              variant="h3"
              style={{
                fontWeight: '350',
                marginLeft: '10px',
                textAlign: 'end',
                marginRight: 20
              }}
            >
              {selectDateToShow()}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '30%'
              }}
            >
              <Filter
                anchorEl={anchorEl}
                filterValues={filterValues}
                handleClose={handleCloseFilter}
                handleChange={handleFilterChange}
                filters={filters}
              />
              <Button
                className={clsx(
                  classes.buttons,
                  classes.outlined,
                  classes.horizontalPadding
                )}
                color="primary"
                onClick={handleClickFilter}
                sx={{ marginRight: 2 }}
              >
                <FilterList className={classes.icon} />
                {t('COMMON.FILTER')}
              </Button>
              <DownloadButton
                onDownload={handleUpfrontRentsListExport}
                title="COMMON.DOWNLOAD_UPFRONT_RENTS"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
