import moment from 'moment';

export default values => {
  return {
    ...values,
    term: values.term || null,
    startDate: moment(),
    firstRentDate: moment()
      .add(1, 'month')
      .add(values.gracePeriod || 0, 'days'),
    endDate: moment()
      .add(values.gracePeriod || 0, 'days')
      .add(values.term, 'months'),
    sendToDigitalSign: true
  };
};
