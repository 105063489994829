export const ParameterSection = {
  GENERAL: 'GENERAL',
  PRICING: 'PRICING',
  NUFI: 'NUFI',
  BILLING: 'BILLING',
  MOFFIN: 'MOFIN',
  SIGNING: 'SIGNING',
  NOTIFICATIONS: 'NOTIFICATIONS'
};

export const ParameterType = {
  BOOLEAN: 'BOOLEAN',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DROPDOWN: 'DROPDOWN',
  ARRAY: 'ARRAY',
  PHONE_NUMBER: 'PHONE_NUMBER'
};

export const MoffinParameter = {
  WARNING_RANGE_MIN: 'WARNING_RANGE_MIN',
  PASSED_RANGE_MIN: 'PASSED_RANGE_MIN',
  PASSED_RANGE_MAX: 'PASSED_RANGE_MAX'
};

export const NufiScoreSection = {
  CRIMINAL_RECORDS: 'CRIMINAL_RECORDS',
  VALIDATE_IDENTITY: 'VALIDATE_IDENTITY',
  VALIDATE_IDENTIFICATION: 'VALIDATE_IDENTIFICATION',
  VALIDATE_IDENTITY_FOR_PERSON_LEGAL: 'VALIDATE_IDENTITY_FOR_PERSON_LEGAL',
  VALIDATE_LEGAL_PERSON_JUDICIAL_RECORD_SCORE:
    'VALIDATE_LEGAL_PERSON_JUDICIAL_RECORD_SCORE',
  VALIDATE_CONTACT_INFORMATION: 'VALIDATE_CONTACT_INFORMATION'
};

export const NufiParameter = {
  CRIMINAL_RECORDS: {
    CRIMINAL_RECORD_SCORE: 'CRIMINAL_RECORD_SCORE'
  },
  VALIDATE_IDENTITY: {
    VALIDATE_CURP_SCORE: 'VALIDATE_CURP_SCORE',
    VALIDATE_RFC_SCORE: 'VALIDATE_RFC_SCORE',
    VALIDATE_SIGER_SCORE: 'VALIDATE_SIGER_SCORE',
    VALIDATE_RUG_SCORE: 'VALIDATE_RUG_SCORE',
    VALIDATE_PROFESSIONAL_CARD_SCORE: 'VALIDATE_PROFESSIONAL_CARD_SCORE',
    VALIDATE_OFAC_SCORE: 'VALIDATE_OFAC_SCORE',
    VALIDATE_BLACK_LIST_SCORE: 'VALIDATE_BLACK_LIST_SCORE',
    VALIDATE_INTERPOL_SCORE: 'VALIDATE_INTERPOL_SCORE',
    VALIDATE_SAT69_SCORE: 'VALIDATE_SAT69_SCORE'
  },
  VALIDATE_IDENTIFICATION: {
    VALIDATE_INE_SCORE: 'VALIDATE_INE_SCORE',
    NOT_VALIDATED_INE_SCORE: 'NOT_VALIDATED_INE_SCORE'
  },
  VALIDATE_IDENTITY_FOR_PERSON_LEGAL: {
    VALIDATE_LEGAL_PERSON_RFC_SCORE: 'VALIDATE_LEGAL_PERSON_RFC_SCORE',
    VALIDATE_LEGAL_PERSON_SIGER_SCORE: 'VALIDATE_LEGAL_PERSON_SIGER_SCORE',
    VALIDATE_LEGAL_PERSON_RUG_SCORE: 'VALIDATE_LEGAL_PERSON_RUG_SCORE',
    VALIDATE_LEGAL_PERSON_OFAC_SCORE: 'VALIDATE_LEGAL_PERSON_OFAC_SCORE',
    VALIDATE_LEGAL_PERSON_SAT69_SCORE: 'VALIDATE_LEGAL_PERSON_SAT69_SCORE'
  },
  VALIDATE_LEGAL_PERSON_JUDICIAL_RECORD_SCORE: {
    VALIDATE_LEGAL_PERSON_JUDICIAL_RECORD_SCORE:
      'VALIDATE_LEGAL_PERSON_JUDICIAL_RECORD_SCORE',
    NOT_VALIDATED_LEGAL_PERSON_JUDICIAL_RECORD_SCORE:
      'NOT_VALIDATED_LEGAL_PERSON_JUDICIAL_RECORD_SCORE'
  },
  VALIDATE_CONTACT_INFORMATION: {
    VALIDATE_PHONE_RISK_SCORE: 'VALIDATE_PHONE_RISK_SCORE',
    VALIDATE_EMAIL_RISK_SCORE: 'VALIDATE_EMAIL_RISK_SCORE'
  }
};

export const BillingParameter = {
  INVOICE_USE_CFDI: 'INVOICE_USE_CFDI',
  EQUIPMENT_SELL_USE_CDFI: 'EQUIPMENT_SELL_USE_CDFI',
  BILLING_PAYMENT_METHOD: 'BILLING_PAYMENT_METHOD',
  BILLING_PAYMENT_FORM: 'BILLING_PAYMENT_FORM'
};

export const BillingPaymentMethod = {
  PUE: {
    key: 'PUE',
    name: 'PUE - Pago en una sola exhibición'
  },
  PPD: {
    key: 'PPD',
    name: 'PPD - Pago en parcialidades o diferido'
  }
};

export const SigningMethod = {
  E_FIRMA: {
    key: 'E_FIRMA',
    name: 'Firma electrónica avanzada (e-Firma)'
  },
  ELECTRONIC_SIGNATURE: {
    key: 'ELECTRONIC_SIGNATURE',
    name: 'Firma electrónica'
  }
};

export const BillingPaymentForm = {
  CASH: {
    key: '01',
    name: '01 - Efectivo'
  },
  TRANSFER: {
    key: '03',
    name: '03 - Transferencia electrónica de fondos'
  },
  UNDEFINED: {
    key: '99',
    name: '99 - Por definir'
  }
};

export const Parameter = {
  IVA: 'IVA',
  MAXIMUM_INITIAL_PAYMENT_PERCENTAGE: 'MAXIMUM_INITIAL_PAYMENT_PERCENTAGE',
  EQUIPMENT_SELL_USE_CDFI: 'EQUIPMENT_SELL_USE_CDFI'
};

export const SendBuroAuthorizationFrequency = {
  WEEKLY: 'WEEKLY',
  FORTHNIGHTLY: 'FORTNIGHTLY',
  MONTHLY: 'MONTHLY'
};

export const BuroAuthorizationFrequencyOptionList = [
  {
    key: SendBuroAuthorizationFrequency.WEEKLY,
    name: `COMMON.${SendBuroAuthorizationFrequency.WEEKLY}`
  },
  {
    key: SendBuroAuthorizationFrequency.FORTHNIGHTLY,
    name: `COMMON.${SendBuroAuthorizationFrequency.FORTHNIGHTLY}`
  },
  {
    key: SendBuroAuthorizationFrequency.MONTHLY,
    name: `COMMON.${SendBuroAuthorizationFrequency.MONTHLY}`
  }
];

export const NotificationParameters = {
  ENABLE_BILLING_NOTIFICATIONS: 'ENABLE_BILLING_NOTIFICATIONS',
  ENABLE_NOTIFICATIONS: 'ENABLE_NOTIFICATIONS',
  SEND_BILLING_EMAIL_NOTIFICATIONS: 'SEND_BILLING_EMAIL_NOTIFICATIONS',
  ENABLE_QUOTES_NOTIFICATIONS: 'ENABLE_QUOTES_NOTIFICATIONS',
  ENABLE_DISTRIBUTORS_NOTIFICATIONS: 'ENABLE_DISTRIBUTORS_NOTIFICATIONS',
  ENABLE_BURO_NOTIFICATIONS: 'ENABLE_BURO_NOTIFICATIONS',
  ENABLE_SIGNING_NOTIFICATIONS: 'ENABLE_SIGNING_NOTIFICATIONS',
  ENABLE_SYSTEM_NOTIFICATIONS: 'ENABLE_SYSTEM_NOTIFICATIONS'
};
