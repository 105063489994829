import React, { useEffect, useMemo, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from 'src/components/Button';
import Loading from 'src/components/Loading';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import DialogTransition from 'src/components/DialogTransition';
import { DOWNLOAD_INVOICES_LIST_IN_ZIP } from 'src/store/constants/CFDI';
import { downloadInvoicesListInZip } from 'src/store/actions/cfdiActions';
import validationSchema from './schema';
import useStyles from './styles';

const makeInitialState = () => ({
  date: moment()
});

const DownloadInvoicesListDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(), []);
  const loadingInvoiceDownload = useSelector(
    isLoadingSelector([DOWNLOAD_INVOICES_LIST_IN_ZIP.action])
  );

  const handleDownloadInvoice = values => {
    dispatch(
      downloadInvoicesListInZip(
        {
          month: moment(values.date).month() + 1,
          year: moment(values.date).year()
        },
        onClose
      )
    );
  };

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title}>
          <IconButton
            key="close"
            color="inherit"
            onClick={onClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('COMMON.DOWNLOAD_INVOICES')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {loadingInvoiceDownload ? (
            <Loading />
          ) : (
            <Formik
              initialValues={initialState}
              validationSchema={validationSchema}
              onSubmit={handleDownloadInvoice}
              enableReinitialize
            >
              {({
                errors,
                handleBlur,
                setFieldValue,
                handleSubmit,
                touched,
                values,
                resetForm
              }) => {
                resetRef.current = () => resetForm(initialState);
                return (
                  <form onSubmit={handleSubmit}>
                    <DatePicker
                      inputFormat="MM/yyyy"
                      views={['month']}
                      disableFuture
                      handleBlur={handleBlur}
                      error={Boolean(touched.date && errors.date)}
                      label={t('COMMON.DATE')}
                      value={values.date}
                      margin="normal"
                      onChange={newValue => {
                        setFieldValue('date', newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          InputProps={{
                            autoComplete: 'off'
                          }}
                          sx={{ width: '100%', margin: '16px 0' }}
                          className={classes.datepicker}
                          {...params}
                          helperText={touched.date && t(errors.date)}
                        />
                      )}
                    />
                    <Grid container direction="column" alignItems="stretch">
                      <Grid item>
                        <Button type="submit">
                          {t('COMMON.DOWNLOAD_INVOICES')}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

DownloadInvoicesListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
export default DownloadInvoicesListDialog;
