/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import OverflowTipography from 'src/components/OverflowTipography';
import TableCell from 'src/components/Table/TableCell';
import moment from 'moment';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';

const UpfrontRentsRow = ({
  client,
  contract,
  description,
  amount,
  iva,
  total,
  createdAt,
  month,
  year,
  monthDifference
}) => {
  return (
    <TableRow hover>
      <TableCell
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <OverflowTipography title={client.name}>
          <Link
            style={{ textDecoration: 'none' }}
            to={`/clients/detail/${client.id}`}
            maxWidth="200px"
          >
            {client.name}
          </Link>
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <Link to={`/contracts/${contract.id}`}>{contract.folio}</Link>
      </TableCell>
      <TableCell>
        <OverflowTipography title={description}>
          {description}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        {selectFormatDate(createdAt, dateFormatType.MONTH_AND_YEAR)}
      </TableCell>
      <TableCell align="center">
        {`${moment()
          .month(month - 1)
          .format('MMM')} - ${moment()
          .year(year)
          .format('YY')}`}
      </TableCell>
      <TableCell align="center">{monthDifference}</TableCell>
      <TableCell align="center">
        {formatAmountWithDecimals(amount, 2)}
      </TableCell>
      <TableCell align="center">{formatAmountWithDecimals(iva, 2)}</TableCell>
      <TableCell align="center">{formatAmountWithDecimals(total, 2)}</TableCell>
    </TableRow>
  );
};

UpfrontRentsRow.propTypes = {
  client: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  iva: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  monthDifference: PropTypes.number.isRequired
};

export default UpfrontRentsRow;
