import moment from 'moment';

const makeInitialState = values => {
  return {
    id: values.id ?? '',
    term: values.term ?? '',
    initialPayment: values.initialPayment ?? '',
    moreInfo: values.moreInfo ?? '',
    price: values.price ?? '',
    initialPaymentAmount: values.initialPaymentAmount ?? '',
    initialPaymentDate: values.initialPaymentDate ?? moment(),
    firstRentDate: values.firstRentDate ?? moment(),
    iva: values.iva ?? ''
  };
};

export default makeInitialState;
