import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'src/utils/common';
import { PersonType } from 'src/utils/constants/clients';
import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.NAME_REQUIRED'),
  paternalName: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.PATERNAL_NAME_REQUIRED')
  }),
  maternalName: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string().required('SCHEMA_ERRORS.MATERNAL_NAME_REQUIRED')
  }),
  email: Yup.string()
    // eslint-disable-next-line func-names
    .test('email', 'SCHEMA_ERRORS.EMAIL_INVALID', function(value) {
      return !value || validateEmail(value);
    })
    .required('SCHEMA_ERRORS.EMAIL_REQUIRED'),
  phone: Yup.string()
    .required('SCHEMA_ERRORS.PHONE_REQUIRED')
    // eslint-disable-next-line func-names
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', function(value) {
      return !value || isValidPhoneNumber(`+${value}`);
    }),
  denomination: Yup.string().when('personType', {
    is: PersonType.LEGAL,
    then: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD')
  })
});
