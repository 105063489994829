import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';
import { useSelector, useDispatch } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  SEND_CLIENT_TO_VALIDATION,
  resendNipCode
} from 'src/store/actions/bureauFormActions';
import Loading from 'src/components/Loading';
import { resendNipSeconds } from 'src/utils/constants/buroForm';
import { formatCountdown } from 'src/utils/common';
import NipInputMask from 'src/components/inputMask/NipInputMask';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';


const StepFive = ({ data, onSubmit, handleBack }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const { t } = useTranslation();
  const loadingSubmitting = useSelector(
    isLoadingSelector([SEND_CLIENT_TO_VALIDATION.action])
  );

  const dispatch = useDispatch();

  const [countdown, setCountdown] = useState(resendNipSeconds);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleResendNip = useCallback(() => {
    dispatch(resendNipCode());
    setCountdown(resendNipSeconds);
  }, [dispatch]);

  const onNext = useCallback(
    async values => {
      onSubmit({ ...values });
    },
    [onSubmit, data]
  );

  return (
    <Box
      sx={{
        height: '100%',
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginBottom: '10px',
            color: theme.palette.primary.main
          }}
        >
          {t('BUREAU_FORM.STEP_FIVE.TITLE')}
        </Typography>

        <Typography
          sx={{
            marginBottom: '20px',
            fontSize: '14px'
          }}
        >
          {t('BUREAU_FORM.STEP_FIVE.DESCRIPTION_1')}
        </Typography>

        <Typography
          sx={{
            marginBottom: '20px',
            fontSize: '14px'
          }}
        >
          {t('BUREAU_FORM.STEP_FIVE.DESCRIPTION_2')}
        </Typography>

        <Typography
          sx={{
            marginBottom: '20px',
            fontSize: '14px'
          }}
        >
          {t('BUREAU_FORM.STEP_FIVE.DESCRIPTION_3')}
        </Typography>
      </Box>
      {loadingSubmitting ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onNext}
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            handleChange,
            handleBlur
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box className={classes.container}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.primary.main
                    }}
                  >
                    {t('BUREAU_FORM.STEP_THREE.TITLE')}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.subtitle}
                    sx={{
                      m: '10px 0',
                      fontSize: '14px'
                    }}
                  >
                    {t('BUREAU_FORM.STEP_THREE.DESCRIPTION')}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.primary.main,
                      m: '10px 0'
                    }}
                  >
                    {t('BUREAU_FORM.STEP_THREE.CODE_QUESTION_DESCRIPTION')}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.subtitle}
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    {t('BUREAU_FORM.STEP_THREE.CODE_QUESTION_ANSWER')}
                  </Typography>

                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="text"
                            onClick={handleResendNip}
                            disabled={countdown > 0}
                          >
                            {countdown > 0
                              ? `${formatCountdown(countdown)}`
                              : t('BUREAU_FORM.STEP_FIVE.RESEND_NIP')}
                          </Button>
                        </InputAdornment>
                      ),
                      inputComponent: NipInputMask,
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.nip && errors.nip)}
                    fullWidth
                    helperText={touched.nip && t(errors.nip)}
                    label="NIP"
                    margin="normal"
                    name="nip"
                    onBlur={handleBlur}
                    onChange={e => {
                      handleChange(e);
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    type="text"
                    value={values.nip}
                    variant="outlined"
                  />
                </Box>

                <Box
                  className={classes.box}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    className={classes.buttons}
                    onClick={() => handleBack()}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.BACK')}
                  </Button>
                  <Button
                    className={clsx(classes.buttons, classes.buttonMain)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.SEND')}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

StepFive.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepFive;
