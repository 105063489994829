import {
  CLEAR_TRANSACTIONS,
  CLEAR_TRANSACTION_OPTIONS,
  LOAD_TRANSACTIONS,
  SAVE_TRANSACTION_OPTIONS
} from '../actions/transactionActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0,
  balance: 0
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSACTIONS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
        balance: action.balance
      };
    }
    case SAVE_TRANSACTION_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_TRANSACTION_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case CLEAR_TRANSACTIONS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default transactionReducer;
