import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  selectDistributorCatalogs,
  selectDistributorDetail
} from 'src/store/selectors/distributorSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_DISTRIBUTOR_AGENTS,
  LOAD_DISTRIBUTOR_CATALOGS,
  LOAD_DISTRIBUTOR_PARAMETER,
  getDistributorParameters,
  loadDistributorEquipmentsCatalog
} from 'src/store/actions/distributorActions';
import { getEquipmentDescription } from 'src/utils/equipments';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import DropdownMenu from 'src/components/DropdownMenu';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { Parameter } from 'src/utils/constants/parameters';
import { loadParameter } from 'src/store/actions/parameterActions';
import { validateTermAndInitialPayment } from 'src/utils/calculator';
import { getDefaultDaysForFirstRentList } from 'src/utils/constants/distributors';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import PaymentMultipleInput from 'src/components/PaymentMultipleInput';
import { formatAmount } from 'src/utils/formatAmout';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import EquipmentsAlert from './EquipmentsAlert';

const StepThree = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const parameter = useSelector(selectDistributorDetail);
  const maxInitialPayment = useSelector(selectIndividualParameter);
  const initialState = useMemo(
    () =>
      makeInitialState({
        ...data,
        equipmentInsuranceIncluded: data.distributor.isInsuranceIncluded
          ? 'true'
          : 'false',
        gracePeriod: Number(parameter.defaultGracePeriod)
      }),
    [data, parameter]
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const equipments = useSelector(selectDistributorCatalogs);
  const isLoading = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );
  const isLoadingEquipments = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_CATALOGS.action])
  );
  const loadingDistributorParameter = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_PARAMETER.action])
  );

  const role = useSelector(selectAuthRole);

  const onNext = useCallback(
    async (values, setFieldError) => {
      const isValidated = validateTermAndInitialPayment(
        values,
        setFieldError,
        {
          minInitialPaymentPercentage: parameter.minInitialPayment,
          maxInitialPaymentPercentage: Number(maxInitialPayment)
        },
        t
      );
      if (!isValidated) return;
      handleNext({ ...values });
    },
    [handleNext, maxInitialPayment, parameter]
  );

  useEffect(() => {
    dispatch(loadDistributorEquipmentsCatalog(data.distributor.id));
    dispatch(getDistributorParameters(data.distributor.id));
    dispatch(loadParameter(Parameter.MAXIMUM_INITIAL_PAYMENT_PERCENTAGE));
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setFieldError }) => {
          onNext(values, setFieldError);
        }}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          resetForm
        }) => {
          resetRef.current = () => resetForm();
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                <EquipmentsAlert
                  equipments={equipments}
                  loading={isLoadingEquipments}
                  distributorRole={role}
                />
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.distributorEquipment && errors.distributorEquipment
                  )}
                >
                  <Autocomplete
                    options={equipments}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value.id
                    }
                    disabled={isLoading || !equipments}
                    id="distributorEquipment"
                    getOptionLabel={option =>
                      option ? getEquipmentDescription(option) : ''
                    }
                    value={values.distributorEquipment ?? ''}
                    fullWidth
                    onChange={(_e, value) => {
                      setFieldValue('distributorEquipment', value ?? '');
                      setFieldValue('price', value.price + value.iva ?? '');
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    label={
                      isLoading
                        ? `${t('COMMON.LOADING')} ${t('COMMON.EQUIPMENT')}`
                        : t('COMMON.EQUIPMENT')
                    }
                    helperText={
                      touched.distributorEquipment &&
                      t(errors.distributorEquipment)
                    }
                  />
                </FormControl>

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.equipmentInsuranceIncluded}
                  setValue={value =>
                    setFieldValue(
                      'equipmentInsuranceIncluded',
                      value === 'true'
                    )
                  }
                  label={t(
                    'DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED'
                  )}
                  error={Boolean(
                    touched.equipmentInsuranceIncluded &&
                      errors.equipmentInsuranceIncluded
                  )}
                  errorText={
                    touched.equipmentInsuranceIncluded &&
                    t(errors.equipmentInsuranceIncluded)
                  }
                />
                <FormControl fullWidth>
                  <DropdownMenu
                    onBlur={handleBlur}
                    label={t('COMMON.DAYS_FOR_FIRST_RENT')}
                    value={values.gracePeriod}
                    name="gracePeriod"
                    setValue={value => setFieldValue('gracePeriod', value)}
                    error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                    errorText={touched.gracePeriod && t(errors.gracePeriod)}
                    list={getDefaultDaysForFirstRentList(
                      parameter.maxGracePeriod
                    )}
                    disabled={
                      isLoadingEquipments || loadingDistributorParameter
                    }
                  />
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  error={Boolean(touched.price && errors.price)}
                  fullWidth
                  helperText={touched.price && t(errors.price)}
                  label={t('COMMON.PRICE')}
                  margin="normal"
                  name="price"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={!data.distributor.isPriceEditable}
                  value={formatAmount(values.price)}
                  variant="outlined"
                />
                <PaymentMultipleInput
                  firstInputName="paymentInputOption"
                  secondInputName="initialPayment"
                  disabled={!equipments || !values.distributorEquipment}
                  label={t('COMMON.INITIAL_PAYMENT')}
                />
              </Box>
              <Box
                className={classes.tableBox}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={handleBack}
                  color="primary"
                  variant="outlined"
                  disabled={isLoading}
                >
                  {t('COMMON.BACK')}
                </Button>
                <Button
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disabled={isLoading}
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepThree.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepThree;
