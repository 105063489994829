/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  IconButton,
  FormControl,
  Box,
  TextField,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import DropdownMenu from 'src/components/DropdownMenu';
import {
  LOAD_CLIENT_CONTRACTS,
  loadClientContracts
} from 'src/store/actions/clientActions';
import { selectClientContractList } from 'src/store/selectors/clientSelectors';
import ContractStatus from 'src/utils/constants/contracts';
import {
  PaymentFormList,
  TransactionsType
} from 'src/utils/constants/transactions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_MANUAL_TRANSACTION } from 'src/store/actions/transactionActions';
import Preloader from 'src/components/Preloader';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { loadPendingPaymentIncomeCFDIs } from 'src/store/actions/cfdiActions';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { LOAD_PENDING_PAYMENT_INCOME_CFDI } from 'src/store/constants/CFDI';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GenerateTransactionDialog = ({ open, handleClose, submit, clientId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [cfdiList, setCfdiList] = useState([]);
  const [cfdisCount, setCfdisCount] = useState(1);
  const contracts = useSelector(selectClientContractList);
  const loading = useSelector(
    isLoadingSelector([CREATE_MANUAL_TRANSACTION.action])
  );
  const isLoadingCfdis = useSelector(
    isLoadingSelector([LOAD_PENDING_PAYMENT_INCOME_CFDI.action])
  );

  const isLoadingContracts = useSelector(
    isLoadingSelector([LOAD_CLIENT_CONTRACTS.action])
  );

  const initialState = makeInitialState();
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleSelectedContract = useCallback(
    (contract, setFieldValue, setTouched) => {
      setFieldValue('contract', contract);
      setTouched({ cfdis: false });
      if (contract) {
        dispatch(loadPendingPaymentIncomeCFDIs(contract.id, setCfdiList));
      }
    }
  );

  const incrementInputCount = (setFieldValue, values) => {
    return () => {
      if (cfdisCount < cfdiList.length) {
        setFieldValue('cfdis', [...values.cfdis, null]);
      }
      setCfdisCount(preValue => preValue + 1);
    };
  };

  useEffect(() => {
    if (open) {
      dispatch(
        loadClientContracts(
          { clientId, status: ContractStatus.IN_PROGRESS },
          false
        )
      );
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
      setCfdisCount(1);
    };
  }, [resetRef, dispatch, id, open]);

  const handleSubmitTransaction = useCallback(
    (values, { resetForm }) => {
      let relatedInvoices = [];

      if (values.cfdis.length) {
        relatedInvoices = values.cfdis.map(cfdi => {
          return {
            invoiceId: cfdi.id,
            amount: cfdi.amountToPay
          };
        });
      }
      submit(
        {
          relatedInvoices,
          paymentForm: values.paymentForm,
          date: values.date,
          type: TransactionsType.PAYMENT
        },
        handleClose
      );
      resetForm(initialState);
    },
    [submit, handleClose, initialState]
  );

  const handleCloseDialog = () => {
    handleClose();
    setCfdiList([]);
  };

  const createHandleRemoveCfdi = (index, setFieldValue, values) => () => {
    const updatedCfdis = values.cfdis.filter((_, i) => i !== index);
    setFieldValue('cfdis', updatedCfdis);
    setCfdisCount(preValue => preValue - 1);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleCloseDialog();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {`${t('COMMON.REGISTER')} ${t(`TRANSACTION.TYPE.PAYMENT`)} - ${t(
            'COMMON.ISSUE_PAYMENT_INVOICE'
          )}`}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        {loading || isLoadingContracts ? (
          <Preloader />
        ) : (
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleSubmitTransaction}
            enableReinitialize
          >
            {({
              errors,
              handleBlur,
              handleSubmit,
              touched,
              setTouched,
              values,
              resetForm,
              setFieldValue
            }) => {
              resetRef.current = () => resetForm();
              const cfdisErrors = errors.cfdis || [];
              return (
                <form onSubmit={handleSubmit}>
                  <DropdownMenu
                    list={contracts.map(contract => ({
                      key: contract,
                      name: `${t('COMMON.CONTRACT')} ${contract.id} - ${
                        contract.client?.name
                      } - ${t('COMMON.RENT')} $${formatAmountWithDecimals(
                        contract.rentIvaIncluded,
                        2
                      )}`
                    }))}
                    value={values.contract}
                    setValue={value =>
                      handleSelectedContract(value, setFieldValue, setTouched)
                    }
                    label={
                      isLoadingContracts
                        ? `${t('COMMON.LOADING')}...`
                        : t('COMMON.CONTRACT')
                    }
                    defaultValue={values.contract}
                    error={Boolean(touched.contract && errors.contract)}
                    errorText={touched.contract && t(errors.contract)}
                    disabled={!contracts.length}
                  />

                  <FormControl fullWidth>
                    {Array(cfdisCount)
                      .fill('')
                      .map((input, index) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            marginTop: index === 0 ? '16px' : '0px'
                          }}
                          key={`box-${index}`}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%'
                            }}
                          >
                            <Autocomplete
                              key={`cfdi-${index}$`}
                              options={cfdiList.filter(
                                cfdi =>
                                  !values.cfdis.some(selectedCfdi => {
                                    if (selectedCfdi) {
                                      return selectedCfdi.id === cfdi.id;
                                    }
                                    return false;
                                  })
                              )}
                              id="cfdis"
                              label={t('COMMON.CFDI')}
                              value={values.cfdis[index] ?? null}
                              loading={isLoadingCfdis}
                              disabled={isLoadingCfdis || !values.contract}
                              loadingText={t('COMMON.LOADING')}
                              getOptionLabel={option =>
                                option
                                  ? `${t('COMMON.SERIE')} ${option.series} - ${
                                      option.folio
                                    } - $${formatAmountWithDecimals(
                                      option?.amountIvaIncluded,
                                      2
                                    )}`
                                  : ''
                              }
                              onChange={(_e, value) => {
                                const updatedCfdis = [...values.cfdis];
                                if (value) {
                                  updatedCfdis[index] = value
                                    ? {
                                        ...value,
                                        amountToPay: value?.amountIvaIncluded
                                      }
                                    : null;
                                }
                                setFieldValue('cfdis', updatedCfdis);
                              }}
                              error={Boolean(
                                touched.cfdis && cfdisErrors[index]
                              )}
                              sx={{
                                width: '60%'
                              }}
                            />

                            <TextField
                              sx={{
                                width: '35%'
                              }}
                              InputProps={{
                                autoComplete: 'off',
                                inputComponent: MoneyMask
                              }}
                              key={`amount-${index}`}
                              error={Boolean(
                                touched.cfdis && cfdisErrors[index]
                              )}
                              disabled={!values.cfdis[index]}
                              label={t('COMMON.AMOUNT_WITH_IVA')}
                              onBlur={handleBlur}
                              name={`cfdis[${index}].amountToPay`}
                              onChange={e => {
                                const newValues = [...values.cfdis];
                                if (
                                  values.cfdis[index]?.amountToPay ||
                                  values.cfdis[index]?.amountToPay === 0
                                ) {
                                  if (e.target.value || e.target.value === 0) {
                                    newValues[index] = {
                                      ...newValues[index],
                                      amountToPay: e.target.value
                                    };
                                  }
                                }

                                setFieldValue('cfdis', newValues);
                              }}
                              value={values.cfdis[index]?.amountToPay ?? ''}
                              variant="outlined"
                            />
                            <IconButton
                              sx={{
                                width: '20px',
                                height: '20px'
                              }}
                              aria-label="delete"
                              key={`delete-${index}`}
                              disabled={cfdisCount === 1}
                              onClick={createHandleRemoveCfdi(
                                index,
                                setFieldValue,
                                values
                              )}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>

                          <FormHelperText error>
                            {touched.cfdis && cfdisErrors[index]
                              ? t(cfdisErrors[index])
                              : ' '}
                          </FormHelperText>
                        </Box>
                      ))}
                  </FormControl>

                  <Box
                    key="addButton"
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      type="button"
                      key="addButton"
                      color="primary"
                      variant="text"
                      disabled={
                        isLoadingCfdis ||
                        !values.contract ||
                        cfdisCount >= cfdiList.length
                      }
                      onClick={incrementInputCount(setFieldValue, values)}
                      startIcon={<AddIcon />}
                    >
                      {t('COMMON.ADD')}
                    </Button>
                  </Box>

                  <TextField
                    fullWidth
                    margin="normal"
                    disabled
                    label="Monto total"
                    InputProps={{
                      readOnly: true,
                      inputComponent: MoneyMask
                    }}
                    value={formatAmountWithDecimals(
                      values.cfdis.reduce((acc, cfdi) => {
                        if (cfdi?.amountToPay) {
                          return acc + cfdi.amountToPay;
                        }
                        return acc;
                      }, 0),
                      2
                    )}
                  />

                  <FormControl fullWidth>
                    <DropdownMenu
                      list={PaymentFormList}
                      value={values.paymentForm}
                      name="paymentForm"
                      setValue={value => setFieldValue('paymentForm', value)}
                      label={t('TRANSACTION.PAYMENT_FORM')}
                      error={Boolean(touched.paymentForm && errors.paymentForm)}
                      errorText={touched.paymentForm && t(errors.paymentForm)}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <DatePicker
                      name="date"
                      label={t('TRANSACTION.DATE')}
                      value={values.date || null}
                      onChange={newValue => {
                        setFieldValue('date', moment(newValue).toDate());
                      }}
                      error={Boolean(touched.date && errors.date)}
                      renderInput={params => (
                        <TextField
                          InputProps={{
                            autoComplete: 'off'
                          }}
                          {...params}
                          sx={{ width: '100%', margin: '16px 0' }}
                          helperText={touched.date && t(errors.date)}
                          FormHelperTextProps={{ sx: { color: 'tomato' } }}
                          error={Boolean(touched.date && errors.date)}
                        />
                      )}
                    />
                  </FormControl>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={
                          isLoadingCfdis || !values.cfdis.length
                            ? classes.buttons
                            : clsx(classes.label, classes.buttons)
                        }
                        type="submit"
                        color="primary"
                        variant="outlined"
                        disabled={
                          loading || isLoadingCfdis || !values.cfdis.length
                        }
                      >
                        {t('COMMON.REGISTER')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

GenerateTransactionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  clientId: PropTypes.number
};

export default GenerateTransactionDialog;
