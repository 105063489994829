import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField
} from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import PhoneInput from 'src/components/PhoneInput';
import Loading from 'src/components/Loading';
import { PersonType } from 'src/utils/constants/clients';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_CLIENT_VALIDATION,
  VALIDATE_CLIENT_BY_RFC,
  associateClientToQuote,
  validateClientExistByRfc
} from 'src/store/actions/bureauFormActions';
import { selectBureauFormClientDetail } from 'src/store/selectors/bureauFormSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { loadDenominationsList } from 'src/store/actions/catalogActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import ClientExistingDialog from './ClientExistingDialog/ClientExistingDialog';

const StepTwo = ({ data, handleNext, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openExistingClientDialog, setOpenExistingClientDialog] = useState(
    false
  );
  const [denominations, setDenominations] = useState([]);

  const initialState = useMemo(() => makeInitialState(data), [data]);
  const loadingExistingClient = useSelector(
    isLoadingSelector([VALIDATE_CLIENT_BY_RFC.action])
  );
  const client = useSelector(selectBureauFormClientDetail);
  const { t } = useTranslation();

  const handleCloseClientExistDialog = useCallback(() => {
    setOpenExistingClientDialog(false);
    dispatch({ type: CLEAR_CLIENT_VALIDATION });
    return handleBack();
  }, [setOpenExistingClientDialog]);

  const handleOpenClientExistDialog = () => {
    setOpenExistingClientDialog(true);
  };

  const handleSubmitClientValidationExist = useCallback(() => {
    return dispatch(
      associateClientToQuote(client.clientId, handleCloseClientExistDialog)
    );
  }, [client, data]);

  const onNext = useCallback(
    async values => {
      handleNext({ ...values });
    },
    [handleNext, data]
  );

  useEffect(() => {
    dispatch(validateClientExistByRfc(initialState.rfc));
    if (data.personType === PersonType.LEGAL) {
      dispatch(loadDenominationsList(setDenominations));
    }
  }, []);

  useEffect(() => {
    if (client) {
      handleOpenClientExistDialog();
    }
  }, [client, loadingExistingClient]);

  return (
    <Box
      sx={{
        height: '100%',
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {loadingExistingClient ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onNext}
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            handleChange,
            handleBlur,
            setFieldValue
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box className={classes.container}>
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && t(errors.name)}
                    label={t('COMMON.NAME')}
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />

                  {values.personType === PersonType.PHYSICAL && (
                    <>
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        error={Boolean(
                          touched.paternalName && errors.paternalName
                        )}
                        fullWidth
                        helperText={
                          touched.paternalName && t(errors.paternalName)
                        }
                        label={t('COMMON.PATERNAL_NAME')}
                        margin="normal"
                        name="paternalName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.paternalName}
                        variant="outlined"
                      />

                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        error={Boolean(
                          touched.maternalName && errors.maternalName
                        )}
                        fullWidth
                        helperText={
                          touched.maternalName && t(errors.maternalName)
                        }
                        label={t('COMMON.MATERNAL_NAME')}
                        margin="normal"
                        name="maternalName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.maternalName}
                        variant="outlined"
                      />
                    </>
                  )}
                  {values.personType === PersonType.LEGAL && (
                    <>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.denomination && errors.denomination
                        )}
                      >
                        <Autocomplete
                          options={denominations}
                          id="denomination"
                          getOptionLabel={option => `${option.name}`}
                          value={denominations.find(
                            equipment => equipment.name === values.name
                          )}
                          error={Boolean(
                            touched.denomination && t(errors.denomination)
                          )}
                          fullWidth
                          onChange={(_e, value) => {
                            setFieldValue('denomination', value?.name ?? '');
                          }}
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          label={t('COMMON.DENOMINATION')}
                          helperText={
                            touched.denomination && t(errors.denomination)
                          }
                        />
                      </FormControl>
                    </>
                  )}

                  <FormControl fullWidth margin="normal">
                    <PhoneInput
                      id="phone-label"
                      name="phone"
                      label={t('COMMON.MOBILE_PHONE')}
                      value={values.phone}
                      error={Boolean(touched.phone && errors.phone)}
                      onBlur={handleBlur}
                      onChange={setFieldValue}
                    />
                    {Boolean(touched.phone && errors.phone) && (
                      <FormHelperText sx={{ color: '#d32f2f' }}>
                        {t(errors.phone)}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && t(errors.email)}
                    label={t('COMMON.EMAIL')}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                  />
                </Box>

                <Box
                  className={classes.box}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    className={classes.buttons}
                    onClick={() => handleBack()}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.BACK')}
                  </Button>
                  <Button
                    className={clsx(classes.buttons, classes.buttonMain)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.NEXT')}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
      <ClientExistingDialog
        open={openExistingClientDialog}
        data={{ ...client, personType: initialState.personType }}
        onClose={handleCloseClientExistDialog}
        onSubmit={handleSubmitClientValidationExist}
      />
    </Box>
  );
};
StepTwo.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepTwo;
