import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import Button from 'src/components/Button';
import {
  LOAD_QUOTE_CATEGORIES,
  loadQuoteCategoriesList
} from 'src/store/actions/quoteActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPercentageToValue,
  getDifferenceBetweenStates
} from 'src/utils/common';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const EditParameterCategoryDialog = ({
  open,
  parameter,
  handleClose,
  onSubmit
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(parameter), [parameter]);
  const loadingCategoriesList = useSelector(
    isLoadingSelector([LOAD_QUOTE_CATEGORIES.action])
  );
  const dispatch = useDispatch();
  const resetRef = useRef();
  const { t } = useTranslation();
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (open) {
      dispatch(loadQuoteCategoriesList(setCategoryList));
    }
  }, [open]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const diference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(diference).length === 0) {
        handleClose();
        return;
      }
      onSubmit(diference);
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-quote-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DISTRIBUTORS_VIEW.PARAMETERS.EDIT_PARAMETER')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleSubmit,
            values,
            touched,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.quoteCategory && errors.quoteCategory)}
                >
                  <Autocomplete
                    options={categoryList}
                    id="quoteCategory"
                    loading={loadingCategoriesList}
                    getOptionLabel={option =>
                      option.name ? `${option.name}` : ''
                    }
                    label={t('DISTRIBUTORS_VIEW.PARAMETERS.CATEGORY')}
                    value={
                      categoryList.find(
                        category => category.id === values.quoteCategory?.id
                      ) ?? null
                    }
                    fullWidth
                    margin="normal"
                    onChange={(_e, value) => {
                      setFieldValue('quoteCategory', value ?? '');
                      setFieldValue('quoteCategoryId', value?.id ?? '');
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    error={Boolean(
                      touched.quoteCategory && errors.quoteCategory
                    )}
                    helperText={
                      touched.quoteCategory && t(errors.quoteCategory)
                    }
                  />
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  disabled
                  margin="normal"
                  label={t('ENUMS.MONTH_RATES.TWELVE_MONTHS_RATE')}
                  value={addPercentageToValue(
                    formatAmountWithDecimals(
                      values.quoteCategory.twelveMonthsRate
                    ) ?? 0
                  )}
                />
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  disabled
                  margin="normal"
                  label={t('ENUMS.MONTH_RATES.EIGHTEEN_MONTHS_RATE')}
                  value={addPercentageToValue(
                    formatAmountWithDecimals(
                      values.quoteCategory.eighteenMonthsRate
                    ) ?? 0
                  )}
                />
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  disabled
                  margin="normal"
                  label={t('ENUMS.MONTH_RATES.TWENTY_FOUR_MONTHS_RATE')}
                  value={addPercentageToValue(
                    formatAmountWithDecimals(
                      values.quoteCategory.twentyFourMonthsRate
                    ) ?? 0
                  )}
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  disabled
                  margin="normal"
                  label={t('ENUMS.MONTH_RATES.THIRTY_SIX_MONTHS_RATE')}
                  value={addPercentageToValue(
                    formatAmountWithDecimals(
                      values.quoteCategory.thirtySixMonthsRate
                    ) ?? 0
                  )}
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  disabled
                  margin="normal"
                  label={t('ENUMS.MONTH_RATES.FORTY_EIGHT_MONTHS_RATE')}
                  value={addPercentageToValue(
                    formatAmountWithDecimals(
                      values.quoteCategory.fortyEigthMonthsRate
                    ) ?? 0
                  )}
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button type="submit" color="primary" variant="outlined">
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditParameterCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  parameter: PropTypes.object
};

export default EditParameterCategoryDialog;
