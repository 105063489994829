import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DownloadButton from 'src/components/DownloadButton';
import useDebounce from 'src/hooks/useDebounce';
import {
  downloadCFDIList,
  loadCFDIS,
  setCFDIsOptions
} from 'src/store/actions/cfdiActions';
import { FilterList, GetApp as Download } from '@mui/icons-material';
import Filter from 'src/components/Filter/Filter';
import { selectCFDISQueryOptions } from 'src/store/selectors/cfdiSelectors';
import { InvoiceTypes } from 'src/utils/constants/cfdi';
import { loadParameters } from 'src/store/actions/parameterActions';
import { ParameterSection } from 'src/utils/constants/parameters';
import { selectParameterList } from 'src/store/selectors/parameterSelector';
import {
  BillingSubSectionList,
  filterParameterByBillingSubSection
} from 'src/views/parameter/ParameterListView/BillingParameter/BillingParameterList';
import SearchBarByType from 'src/components/SearchBarByType';
import { CLEAR_CFDIS_OPTIONS } from 'src/store/constants/CFDI';
import FILTER_TYPES from 'src/utils/constants/filter';
import selectFormatDate from 'src/utils/selectDateFormat';
import moment from 'moment';
import dateFormatType from 'src/utils/dateFormatType';
import useStyles from './styles';
import DownloadInvoicesListDialog from './DownloadInvoicesListDialog/DownloadInvoicesListDialog';

const searchTypes = [
  { value: 'client', label: 'NAME', type: 'text' },
  { value: 'folio', label: 'FOLIO', type: 'text' },
  { value: 'rfc', label: 'RFC', type: 'text' }
];

const typeFilters = [
  {
    title: 'COMMON.DATE',
    controlGroup: FILTER_TYPES.DATE,
    items: [
      {
        label: 'COMMON.DATE',
        value: 'date',
        view: ['year', 'month']
      }
    ]
  },
  {
    title: 'COMMON.TYPE',
    controlGroup: FILTER_TYPES.CHECKBOX,
    items: [
      {
        label: 'ENUMS.INVOICE_TYPES.EXPENSE',
        value: InvoiceTypes.EXPENSE
      },
      {
        label: 'ENUMS.INVOICE_TYPES.INCOME',
        value: InvoiceTypes.INCOME
      },
      {
        label: 'ENUMS.INVOICE_TYPES.PAYMENT',
        value: InvoiceTypes.PAYMENT
      },
      {
        label: 'ENUMS.INVOICE_TYPES.PAYROLL',
        value: InvoiceTypes.PAYROLL
      },
      {
        label: 'ENUMS.INVOICE_TYPES.TRANSFER',
        value: InvoiceTypes.TRANSFER
      }
    ]
  }
];

function getFilterOptions(options, seriesOptions) {
  const type = [];
  const series = [];
  let month = null;
  let year = null;

  if (options[InvoiceTypes.EXPENSE]) {
    type.push(InvoiceTypes.EXPENSE);
  }
  if (options[InvoiceTypes.INCOME]) {
    type.push(InvoiceTypes.INCOME);
  }
  if (options[InvoiceTypes.PAYMENT]) {
    type.push(InvoiceTypes.PAYMENT);
  }
  if (options[InvoiceTypes.PAYROLL]) {
    type.push(InvoiceTypes.PAYROLL);
  }
  if (options[InvoiceTypes.TRANSFER]) {
    type.push(InvoiceTypes.TRANSFER);
  }

  seriesOptions.forEach(option => {
    if (options[option.value]) {
      series.push(option.value);
    }
  });

  if (options.date) {
    month = options.date.month() + 1;
    year = options.date.year();
  }

  return { type, series, month, year };
}

const Toolbar = ({ className, ...rest }) => {
  const [searchValue, setSearchValue] = useState('');
  const [filterValues, setFilterValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState('name');
  const [openDownloadInvoices, setOpenDownloadInvoices] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCFDISQueryOptions);
  const parameters = useSelector(selectParameterList);

  const seriesOptions = filterParameterByBillingSubSection(
    parameters,
    BillingSubSectionList.INVOICE_TYPES
  ).map(parameter => {
    return {
      label: parameter.value,
      value: parameter.value
    };
  });

  const filters = [
    ...typeFilters,
    {
      title: 'COMMON.SERIE',
      controlGroup: 'checkbox',
      items: seriesOptions
    }
  ];

  const debounceSearch = useDebounce(
    (search, queryOpts) =>
      dispatch(loadCFDIS({ ...queryOpts, ...search, page: 0 })),
    400
  );

  const handleOpenDownloadInvoices = () => {
    setOpenDownloadInvoices(true);
  };

  const handleCloseDownloadInvoices = () => {
    setOpenDownloadInvoices(false);
  };

  const handleSearch = useCallback(
    (e, criteria) => {
      const { value } = e.target;
      setSearchValue(value);
      setSearchCriteria(criteria);
      debounceSearch({ [criteria]: value }, queryOptions);
    },
    [setSearchValue, debounceSearch, searchCriteria]
  );

  const handleSearchCriteria = useCallback(() => {
    setSearchValue('');
    dispatch(
      setCFDIsOptions({
        ...queryOptions,
        [searchCriteria]: ''
      })
    );
  }, [dispatch, queryOptions]);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchValue, {
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event, seriesOptions)
      });
    },
    [
      setFilterValues,
      searchValue,
      debounceSearch,
      queryOptions,
      getFilterOptions
    ]
  );

  useEffect(() => {
    dispatch(
      loadCFDIS({
        ...queryOptions,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      })
    );
    dispatch(
      loadParameters({
        section: ParameterSection.BILLING
      })
    );

    return () => {
      dispatch({ type: CLEAR_CFDIS_OPTIONS });
    };
  }, []);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              style={{ fontWeight: '400', fontSize: '1.5rem' }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.CFDI_ISSUED')}
            </Typography>
            <Typography
              color="primary"
              variant="h3"
              style={{
                fontWeight: '350',
                marginLeft: '10px',
                textAlign: 'end',
                fontSize: '1.3rem'
              }}
            >
              {selectFormatDate(
                moment({
                  month: queryOptions.month - 1,
                  year: queryOptions.year
                }),
                dateFormatType.MONTH_AND_YEAR
              )}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <SearchBarByType
              types={searchTypes}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleSearchCriteria={handleSearchCriteria}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '50%'
              }}
            >
              <Filter
                anchorEl={anchorEl}
                filterValues={filterValues}
                handleClose={handleCloseFilter}
                handleChange={handleFilterChange}
                filters={filters}
              />
              <Button
                className={clsx(
                  classes.buttons,
                  classes.outlined,
                  classes.horizontalPadding
                )}
                sx={{ marginRight: 2, marginLeft: 2 }}
                color="primary"
                onClick={handleClickFilter}
              >
                <FilterList className={classes.icon} />
                {t('COMMON.FILTER')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.outlined)}
                sx={{ marginRight: 2, minWidth: '200px' }}
                onClick={handleOpenDownloadInvoices}
              >
                <Download sx={{ marginRight: 1 }} />
                <Typography variant="span">
                  {t('COMMON.DOWNLOAD_INVOICES')}
                </Typography>
              </Button>
              <DownloadButton
                onDownload={() => {
                  dispatch(downloadCFDIList());
                }}
                title="COMMON.DOWNLOAD_CFDIS"
              />
            </Box>
            <DownloadInvoicesListDialog
              open={openDownloadInvoices}
              onClose={handleCloseDownloadInvoices}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
