import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const ConfirmationDialog = ({
  open,
  handleClose,
  onSubmit,
  title,
  description,
  children,
  width = 60,
  ...rest
}) => {
  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      width: theme.spacing(width)
    },
    marginBottom: '1.5rem'
  }));

  const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  }));

  const StyledCloseIcon = styled(IconButton)(() => ({
    float: 'right',
    width: '20px',
    height: '20px'
  }));

  const StyledTitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
  }));

  const StyledDialogDescription = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2.5),
    paddingLeft: '1.5rem'
  }));

  const StyledGridContainer = styled(Grid)(() => ({
    marginBottom: '1.5rem',
    direction: 'column',
    alignItems: 'stretch'
  }));

  const StyledGridItem = styled(Grid)(({ theme }) => ({
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    borderRadius: '4px',
    padding: theme.spacing(1),
    minWidth: '100%',
    margin: '0 auto',
    marginBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: 11
    }
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    borderRadius: '4px',
    padding: theme.spacing(1),
    minWidth: '100%',
    margin: '0 auto',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: 11
    }
  }));

  const StyledConfirmButton = styled(StyledButton)(({ theme }) => ({
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  }));

  const { t } = useTranslation();

  const handleStatusChange = () => {
    onSubmit();
  };

  return (
    <StyledDialog
      disableEscapeKeyDown
      open={open}
      keepMounted
      onClose={handleClose}
      {...rest}
    >
      <StyledDialogTitle id="form-delete">
        <StyledCloseIcon
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </StyledCloseIcon>
        <StyledTitleTypography variant="h2">{title}</StyledTitleTypography>
      </StyledDialogTitle>
      <StyledDialogDescription id="form-delete-dialog">
        {children || description}
      </StyledDialogDescription>
      <StyledGridContainer container>
        <StyledGridItem item>
          <StyledConfirmButton onClick={handleStatusChange} variant="outlined">
            {t('COMMON.ACCEPT')}
          </StyledConfirmButton>
        </StyledGridItem>
        <StyledGridItem item>
          <StyledButton onClick={handleClose} variant="outlined">
            {t('COMMON.CANCEL')}
          </StyledButton>
        </StyledGridItem>
      </StyledGridContainer>
    </StyledDialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node
};

export default ConfirmationDialog;
