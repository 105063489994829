import moment from 'moment';
import {
  createTransaction,
  getTransactionById,
  getAllTransactions,
  downloadInvoiceById,
  getMovements,
  downloadExportedDistributors,
  assignTransctionToEquipment
} from 'src/services/transactionsService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  TransactionsCategory,
  TransactionsStatus,
  TransactionsType
} from 'src/utils/constants/transactions';
import { FirstDayOfMonth, SnackBarVariants } from 'src/utils/constants/common';
import { createUpfrontRent } from 'src/services/contractService';
import { selectTransactionsQueryOptions } from '../selectors/transactionSelectors';
import { showMessage } from './snackBarActions';

export const LOAD_TRANSACTIONS = asyncActionCreator('LOAD_TRANSACTIONS');
export const DOWNLOAD_TRANSACTIONS_LIST = asyncActionCreator(
  'DOWNLOAD_TRANSACTIONS_LIST'
);
export const CREATE_MANUAL_TRANSACTION = asyncActionCreator(
  'CREATE_MANUAL_TRANSACTION'
);
export const LOAD_MOVEMENTS_BY_CONTACT = asyncActionCreator(
  'LOAD_MOVEMENTS_BY_CONTACT'
);
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
export const SAVE_TRANSACTION_OPTIONS = 'SAVE_TRANSACTION_OPTIONS';
export const CLEAR_TRANSACTION_OPTIONS = 'CLEAR_TRANSACTION_OPTIONS';
export const DOWNLOAD_INVOICE = asyncActionCreator('DOWNLOAD_INVOICE');
export const ASSIGN_TRANSACTION_TO_CONTRACT = asyncActionCreator(
  'ASSIGN_TRANSACTION_TO_CONTRACT'
);

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectTransactionsQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.id) {
    reqOptions.id = queryOpts.id;
  }
  if (queryOpts.clientName) {
    reqOptions.clientName = queryOpts.clientName;
  }
  if (queryOpts.month) {
    reqOptions.afterDate = moment(queryOpts.month)
      .date(FirstDayOfMonth)
      .format('YYYY-MM-DD');
    reqOptions.beforeDate = moment(queryOpts.month)
      .endOf('month')
      .format('YYYY-MM-DD');
  }
  if (queryOpts.beforeDate) {
    reqOptions.beforeDate = moment(queryOpts.beforeDate)
      .endOf('day')
      .toISOString();
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_TRANSACTION_OPTIONS });
};

export const loadTransactions = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_TRANSACTIONS.start });

    dispatch({
      type: SAVE_TRANSACTION_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const transaction = await getAllTransactions(reqOptions);

    return dispatch({
      type: LOAD_TRANSACTIONS.success,
      ...transaction
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_TRANSACTIONS.failure });
  }
};

export const loadMovementsByContract = (
  contractId,
  status = [TransactionsStatus.PENDING, TransactionsStatus.COMPLETED],
  setState = () => {}
) => async dispatch => {
  try {
    dispatch({ type: LOAD_MOVEMENTS_BY_CONTACT.start });

    const movements = await getMovements({ contractId, status });

    setState(movements);

    return dispatch({ type: LOAD_MOVEMENTS_BY_CONTACT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_MOVEMENTS_BY_CONTACT.failure });
  }
};

export const loadClientTransaction = (id, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_TRANSACTIONS.start });

    dispatch({
      type: SAVE_TRANSACTION_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const transaction = await getTransactionById({
      id,
      options: reqOptions
    });

    return dispatch({
      type: LOAD_TRANSACTIONS.success,
      ...transaction
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_TRANSACTIONS.failure });
  }
};

export const createManualTransaction = (
  values,
  handleClose = () => {}
) => async dispatch => {
  try {
    dispatch({ type: CREATE_MANUAL_TRANSACTION.start });

    if (values.type === TransactionsType.PAYMENT) {
      let data = {
        clientId: values.clientId,
        type: values.type,
        date: values.date,
        paymentForm: values.paymentForm
      };

      if (values.relatedInvoices && values.relatedInvoices.length) {
        data.relatedInvoices = values.relatedInvoices;
      } else {
        data = {
          ...data,
          equipmentId:
            values.category === TransactionsCategory.DISCOUNT
              ? values.equipmentId
              : values.contract.equipment.id,
          comment: values.comment,
          doNotBillTransaction: values.doNotBillTransaction,
          relatedInvoices: [
            { invoiceId: values.cfdi.id, amount: values.amount }
          ]
        };

        if (values.category === TransactionsCategory.DISCOUNT) {
          data.category = values.category;
        }
      }
      await createTransaction(data);
    } else if (values.category === TransactionsCategory.UPFRONT_RENT) {
      // This is temporary until separate upfront rents to another window
      await createUpfrontRent(values);
    } else {
      await createTransaction(values);
    }

    dispatch(
      showMessage({
        message: 'CREATE_MANUAL_TRANSACTION',
        variant: 'success'
      })
    );
    dispatch(loadClientTransaction(values.clientId));
    handleClose();
    return dispatch({
      type: CREATE_MANUAL_TRANSACTION.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_MANUAL_TRANSACTION.failure });
  }
};

export const downloadInvoice = id => async dispatch => {
  try {
    dispatch({ type: DOWNLOAD_INVOICE.start });

    const invoice = await downloadInvoiceById(id);

    return dispatch({
      type: DOWNLOAD_INVOICE.success,
      ...invoice
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_INVOICE.failure });
  }
};

export const downloadTransactionsList = options => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DOWNLOAD_TRANSACTIONS_LIST.start });

    dispatch({
      type: SAVE_TRANSACTION_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState, false);

    await downloadExportedDistributors({
      ...reqOptions,
      customErrorMessage: 'TRANSACTIONS_NOT_FOUND'
    });

    dispatch(
      showMessage({
        message: 'DOWNLOAD_TRANSACTIONS_LIST',
        variant: SnackBarVariants.SUCCESS
      })
    );

    return dispatch({
      type: DOWNLOAD_TRANSACTIONS_LIST.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: DOWNLOAD_TRANSACTIONS_LIST.failure });
  }
};

export const assignTransactionToContract = (
  id,
  values,
  handleClose
) => async dispatch => {
  try {
    dispatch({ type: ASSIGN_TRANSACTION_TO_CONTRACT.start });

    await assignTransctionToEquipment({
      equipmentId: values.contract.equipment.id,
      transactionId: id
    });

    dispatch(
      showMessage({
        message: 'ASSIGN_TRANSACTION_TO_CONTRACT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    handleClose();

    return dispatch({
      type: ASSIGN_TRANSACTION_TO_CONTRACT.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: ASSIGN_TRANSACTION_TO_CONTRACT.failure });
  }
};
