import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { successSelector } from 'src/store/selectors/statusSelector';
import Loading from 'src/components/Loading';
import { useSelector } from 'react-redux';
import { SEARCH_CLIENT_WITH_RFC } from 'src/store/actions/clientActions';
import { addPointsPhone } from 'src/utils/common';
import AlertMessage from 'src/components/Alert/Alert';
import useStyles from './styles';

const CreateAvalFromClientDialog = ({ open, handleClose, onSubmit, data }) => {
  const classes = useStyles();
  const success = useSelector(
    successSelector([SEARCH_CLIENT_WITH_RFC.action])
  );

  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-distributor-delete"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {success ? (
        <>
          <DialogContent
            className={classes.confirmation}
            id="form-distributor-delete-dialog"
          >
            <DialogContentText>
              <AlertMessage
                open
                severity='warning'
                title='COMMON.WARNING'
                text='CLIENTS_VIEW.AVAL_DETAIL.RFC_ALREADY_EXIST_IN_SYSTEM_DESCRIPTION'
                hideCloseIcon
              />
            </DialogContentText>
            <Table classes={{ root: classes.tableRoot }} component="div">
              <TableBody classes={{ root: classes.tableBody }} component="div">
                <TableRow component="div">
                  <TableCell
                    align="start"
                    colSpan={2}
                    className={classes.reviewDataSubtitle}
                  >
                    {t('CLIENTS_VIEW.CREATION_FORM.CLIENT_DATA')}
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('COMMON.NAME')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {data.fullName}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('COMMON.EMAIL')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {data.email}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('COMMON.PHONE')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {addPointsPhone(data.phone)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('COMMON.RFC')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {data.rfc}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button
              className={clsx(classes.buttons)}
              onClick={handleClose}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.CANCEL')}
            </Button>

            <Button
              className={clsx(classes.buttonMain, classes.buttons)}
              onClick={onSubmit}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.CONTINUE')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <Loading />
      )}
    </Dialog>
  );
};

CreateAvalFromClientDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default CreateAvalFromClientDialog;
