import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { useHistory } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import GeneralView from './GeneralDetail/General';
import ContractCalendar from './ContractCalendarDetail/ContractCalendar';
import Contributions from './ContributionsList/Contributions';
import ContractStatus, {
  ContractDetailTabsIndex
} from '../../../utils/constants/contracts';
import RentBalanceSchedule from './RentBalanceSchedule/RentBalanceSchedule';
import AvalListView from './AvalListView/AvalListView';

export default function ContracTabs({ contract }) {
  const { goBack, push } = useHistory();
  const { id, tab } = useParams();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(false);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/contracts/${id}/${newValue}`);
    },
    [setValue]
  );

  useEffect(() => {
    if (id && !tab) {
      setValue(ContractDetailTabsIndex.DETAIL);
    }
    if (tab) {
      if (Object.values(ContractDetailTabsIndex).includes(tab)) {
        setValue(tab);
      } else {
        push(`/contracts/${id}`);
      }
    }
  }, [id, tab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.CONTRACT']}
        current={contract.id}
      />
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab
            label={t('COMMON.DETAILS')}
            value={ContractDetailTabsIndex.DETAIL}
          />
          <Tab
            label={t('COMMON.AVALS')}
            value={ContractDetailTabsIndex.AVALS}
          />
          <Tab
            label={t('COMMON.CONTRIBUTIONS')}
            value={ContractDetailTabsIndex.CONTRIBUTIONS}
            disabled={
              contract.status !== ContractStatus.IN_PROGRESS &&
              contract.status !== ContractStatus.ENDED
            }
          />
          <Tab
            label={t('CONTRACT_VIEW.CONTRACT_DETAIL.RENT_BALANCE')}
            value={ContractDetailTabsIndex.RENT_BALANCE}
          />
          <Tab
            label={t('CONTRACT_VIEW.CONTRACT_DETAIL.PAYMENT_SCHEDULE')}
            value={ContractDetailTabsIndex.PAYMENT_SCHEDULE}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={ContractDetailTabsIndex.DETAIL}>
        <GeneralView contract={contract} />
      </TabPanel>

      <TabPanel value={value} index={ContractDetailTabsIndex.AVALS}>
        <AvalListView />
      </TabPanel>

      <TabPanel value={value} index={ContractDetailTabsIndex.CONTRIBUTIONS}>
        <Contributions contract={contract} />
      </TabPanel>

      <TabPanel value={value} index={ContractDetailTabsIndex.RENT_BALANCE}>
        <ContractCalendar contract={contract} />
      </TabPanel>

      <TabPanel value={value} index={ContractDetailTabsIndex.PAYMENT_SCHEDULE}>
        <RentBalanceSchedule />
      </TabPanel>
    </Box>
  );
}

ContracTabs.propTypes = {
  contract: PropTypes.object.isRequired
};
