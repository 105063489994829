import * as Yup from 'yup';

export default Yup.object().shape({
  term: Yup.string().required('SCHEMA_ERRORS.TERM_REQUIRED'),
  moreInfo: Yup.string(),
  initialPaymentDate: Yup.date()
    .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  gracePeriod: Yup.string().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
  firstRentDate: Yup.date()
    .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
    .required('SCHEMA_ERRORS.REQUIRED_FIELD')
});
