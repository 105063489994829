import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getParameters = serviceBase({
  request: params => axiosClient.get('/parameter', { params }),
  retry: 0
});

export const editParameter = serviceBase({
  request: ({ id, value }) => axiosClient.put(`/parameter/${id}`, { value }),
  retry: 0
});

export const editNuFiParameter = serviceBase({
  request: ({ parameterList }) =>
    axiosClient.put(`/parameter/nufi/update`, { ...parameterList })
});

export const getParameterByName = serviceBase({
  request: parameter => axiosClient.get(`/parameter/${parameter}`)
});
