import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  ASSOCIATE_CLIENT_TO_QUOTE,
  VALIDATE_CLIENT_BY_RFC
} from 'src/store/actions/bureauFormActions';
import Loading from 'src/components/Loading';
import { getClientFullName } from 'src/utils/common';
import { useSelector } from 'react-redux';
import useStyles from './styles';

const ClientExistingDialog = ({ open, onClose, onSubmit, data }) => {
  const classes = useStyles();
  const success = useSelector(successSelector([VALIDATE_CLIENT_BY_RFC.action]));
  const loading = useSelector(
    isLoadingSelector([ASSOCIATE_CLIENT_TO_QUOTE.action])
  );

  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
    >
      <DialogTitle className={classes.header} id="form-distributor-delete">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          className={classes.header}
          color="primary"
          variant="h2"
          align="center"
        >
          {t('BUREAU_FORM.CLIENT_EXISTING_DIALOG.TITLE')}
        </Typography>
      </DialogTitle>
      {success || loading ? (
        <>
          <DialogContent
            className={classes.confirmation}
            id="form-distributor-delete-dialog"
          >
            <DialogContentText>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ mb: '10px' }}
                align="center"
              >
                {t('BUREAU_FORM.CLIENT_EXISTING_DIALOG.SUBTITLE')}
              </Typography>
            </DialogContentText>
            <Table classes={{ root: classes.tableRoot }} component="div">
              <TableBody classes={{ root: classes.tableBody }} component="div">
                <TableRow component="div">
                  <TableCell
                    align="start"
                    colSpan={2}
                    className={classes.reviewDataSubtitle}
                  >
                    {t('CLIENTS_VIEW.CREATION_FORM.CLIENT_DATA')}
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('CLIENTS_VIEW.CREATION_FORM.PERSON_TYPE')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {t(`CLIENTS_VIEW.CREATION_FORM.${data.personType}`)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('COMMON.NAME')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {getClientFullName({
                        ...data
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow component="div">
                  <TableCell
                    component="div"
                    className={classes.reviewDataLabel}
                  >
                    {t('COMMON.RFC')}
                  </TableCell>
                  <TableCell component="div">
                    <Typography variant="body2" color="textSecondary">
                      {data.rfc}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions className={classes.actionContainer}>
            <Button
              className={clsx(classes.buttons)}
              onClick={onClose}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.CANCEL')}
            </Button>

            <Button
              className={clsx(classes.buttonMain, classes.buttons)}
              onClick={onSubmit}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.ACCEPT')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <Loading />
      )}
    </Dialog>
  );
};

ClientExistingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default ClientExistingDialog;
