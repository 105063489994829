import * as Yup from 'yup';
import { PersonType } from 'src/utils/constants/clients';

export default Yup.object().shape({
  industry: Yup.string().required('SCHEMA_ERRORS.INDUSTRY_REQUIRED'),
  birthDate: Yup.date()
    .typeError('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CONSTITUTION_DATE_REQUIRED')
    .required('SCHEMA_ERRORS.CLIENT_DETAIL_FORM.CONSTITUTION_DATE_REQUIRED'),
  rfc: Yup.string().when('personType', {
    is: PersonType.PHYSICAL,
    then: Yup.string()
      .matches(
        /^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
        'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
      )
      .required('SCHEMA_ERRORS.RFC_REQUIRED'),
    otherwise: Yup.string()
      .matches(
        /^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
        'SCHEMA_ERRORS.RFC_FORMAT_INVALID'
      )
      .required('SCHEMA_ERRORS.RFC_REQUIRED')
  }),
  deedNumber: Yup.string(),
  notaryName: Yup.string(),
  notaryPracticeNumberAndCity: Yup.string(),
  registryRegistrationNumber: Yup.string()
});
