import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DetailRow from 'src/components/DetailRow';
import { addPercentageToValue } from 'src/utils/common';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';

const ParameterCategoryDetailInfo = ({ distributor }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.CATEGORY')}
        value={
          distributor.quoteCategory
            ? distributor.quoteCategory.name
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('ENUMS.MONTH_RATES.TWELVE_MONTHS_RATE')}
        value={
          distributor.quoteCategory
            ? addPercentageToValue(
                formatAmountWithDecimals(
                  distributor.quoteCategory.twelveMonthsRate
                )
              )
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('ENUMS.MONTH_RATES.EIGHTEEN_MONTHS_RATE')}
        value={
          distributor.quoteCategory
            ? addPercentageToValue(
                formatAmountWithDecimals(
                  distributor.quoteCategory.eighteenMonthsRate
                )
              )
            : t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('ENUMS.MONTH_RATES.TWENTY_FOUR_MONTHS_RATE')}
        value={
          distributor.quoteCategory
            ? addPercentageToValue(
                formatAmountWithDecimals(
                  distributor.quoteCategory.twentyFourMonthsRate
                )
              )
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('ENUMS.MONTH_RATES.THIRTY_SIX_MONTHS_RATE')}
        value={
          distributor.quoteCategory
            ? addPercentageToValue(
                formatAmountWithDecimals(
                  distributor.quoteCategory.thirtySixMonthsRate
                )
              )
            : t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('ENUMS.MONTH_RATES.FORTY_EIGHT_MONTHS_RATE')}
        value={
          distributor.quoteCategory
            ? addPercentageToValue(
                formatAmountWithDecimals(
                  distributor.quoteCategory.fortyEigthMonthsRate
                )
              )
            : t('COMMON.NO_DATA')
        }
      />
    </>
  );
};

ParameterCategoryDetailInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};

export default ParameterCategoryDetailInfo;
