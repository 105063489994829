import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableSortLabel, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import { RentResumeBalanceType } from 'src/utils/constants/accounting';
import useStyles from './styles';
import RentResumeRow from './RentResumeRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ rentResumeList, balanceType }) =>
  rentResumeList.map(rentResume => (
    <RentResumeRow
      key={rentResume.id}
      {...rentResume}
      balanceType={balanceType}
    />
  ));

const RentResumeTable = ({
  className,
  rentResumeList,
  count,
  page,
  handleChangePage,
  loading,
  balanceType,
  handleSortChange,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isAsc = order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSortChange(property, isAsc ? 'desc' : 'asc');
    },
    [order, orderBy, setOrder, setOrderBy, handleSortChange]
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort]
  );

  const theads = useMemo(
    () => () => (
      <TableRow align="center">
        {balanceType === RentResumeBalanceType.CONTRACT && (
          <TableCell
            variant="head"
            key="contract-id"
            align="center"
            sx={{ width: '5%' }}
          >
            {t('COMMON.ID')}
          </TableCell>
        )}

        <TableCell
          variant="head"
          key="client"
          sortDirection={orderBy === 'name' ? order : false}
          sx={{ width: '15%' }}
        >
          <TableSortLabel
            classes={{ root: classes.sortActive, icon: classes.sortActive }}
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'desc'}
            onClick={createSortHandler('name')}
            hideSortIcon={orderBy !== 'name'}
          >
            {t('COMMON.CLIENT')}
          </TableSortLabel>
        </TableCell>

        <TableCell
          variant="head"
          key="initialBalance"
          align="center"
          sx={{ width: '9%' }}
        >
          <Typography variant="head" color="textSecondary" noWrap>
            {t('RENT_RESUME.INITIAL_BALANCE')}
          </Typography>
        </TableCell>

        <TableCell
          variant="head"
          key="initialPayment"
          align="center"
          sx={{ width: '9%' }}
        >
          <Typography variant="head" color="textSecondary" noWrap>
            {t('RENT_RESUME.INITIAL_PAYMENT')}
          </Typography>
        </TableCell>

        <TableCell
          variant="head"
          key="rent"
          align="center"
          sortDirection={orderBy === 'rent' ? order : false}
          sx={{ width: '9%' }}
        >
          <TableSortLabel
            classes={{ root: classes.sortActive, icon: classes.sortActive }}
            active={orderBy === 'rent'}
            direction={orderBy === 'rent' ? order : 'desc'}
            onClick={createSortHandler('rent')}
            hideSortIcon={orderBy !== 'rent'}
          >
            {t('RENT_RESUME.RENT')}
          </TableSortLabel>
        </TableCell>

        <TableCell
          variant="head"
          key="capitalContribution"
          align="center"
          sx={{ width: '9%' }}
        >
          {t('COMMON.CONTRIBUTIONS')}
        </TableCell>

        <TableCell
          variant="head"
          key="residualPayment"
          align="center"
          sx={{ width: '9%' }}
        >
          {t('RENT_RESUME.RESIDUAL_PAYMENT')}
        </TableCell>

        <TableCell
          variant="head"
          key="interests"
          align="center"
          sx={{ width: '9%' }}
        >
          {t('RENT_RESUME.INTERESTS')}
        </TableCell>

        <TableCell
          variant="head"
          key="discounts"
          align="center"
          sx={{ width: '9%' }}
        >
          {t('RENT_RESUME.DISCOUNTS')}
        </TableCell>

        <TableCell
          variant="head"
          key="payments"
          align="center"
          sx={{ width: '9%' }}
        >
          {t('RENT_RESUME.PAYMENTS')}
        </TableCell>

        <TableCell
          variant="head"
          key="finalBalance"
          align="center"
          sx={{ width: '9%' }}
          sortDirection={orderBy === 'finalBalance' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'finalBalance'}
            direction={orderBy === 'finalBalance' ? order : 'desc'}
            onClick={createSortHandler('finalBalance')}
            hideSortIcon={orderBy !== 'finalBalance'}
          >
            <Typography variant="head" color="textSecondary" noWrap>
              {t('RENT_RESUME.FINAL_BALANCE')}
            </Typography>
          </TableSortLabel>
        </TableCell>
      </TableRow>
    ),
    [classes, order, orderBy, createSortHandler]
  );

  const tbody = useMemo(
    () => () => (
      <Tbody rentResumeList={rentResumeList} balanceType={balanceType} />
    ),
    [rentResumeList]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, rentResumeList]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

RentResumeTable.propTypes = {
  className: PropTypes.string,
  rentResumeList: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
  balanceType: PropTypes.string,
  handleSortChange: PropTypes.func
};

RentResumeTable.defaultProps = {
  count: 0
};

export default RentResumeTable;
