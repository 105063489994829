import {
  CLEAR_BUREAU_FORM_ID,
  CLEAR_CLIENT_VALIDATION,
  CREATE_BURO_FORM,
  GET_TOKEN_DATA,
  VALIDATE_CLIENT_BY_RFC
} from '../actions/bureauFormActions';

const initialState = {
  clientDetail: null,
  tokenData: null,
  formId: null
};

const bureauFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_CLIENT_BY_RFC.success: {
      return {
        ...state,
        clientDetail: action.payload
      };
    }
    case CLEAR_CLIENT_VALIDATION: {
      return {
        ...state,
        clientDetail: null
      };
    }
    case GET_TOKEN_DATA: {
      return {
        ...state,
        tokenData: action.payload
      };
    }
    case CREATE_BURO_FORM.success: {
      return {
        ...state,
        formId: action.payload
      };
    }
    case CLEAR_BUREAU_FORM_ID: {
      return {
        ...state,
        formId: null
      };
    }

    default: {
      return state;
    }
  }
};

export default bureauFormReducer;
