import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const InvestorStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const InvestorDetailTabsIndex = {
  GENERAL: 'general',
  KYC: 'kyc',
  IDENTITY: 'identity',
  INVESTMENTS: 'investments',
  ACCOUNT_STATEMENT: 'accountStatement'
};
export const AgentDetailTabsIndex = {
  GENERAL: 'general'
};

export const getTenureFromDate = (date, t) => {
  const years = moment().diff(moment(date), 'years');
  const months = moment().diff(moment(date), 'months') % 12;

  if (years > 0) {
    if (months > 0) {
      return `${years} ${
        years === 1 ? t('COMMON.YEAR') : t('COMMON.YEARS')
      }, ${months} ${months === 1 ? t('COMMON.MONTH') : t('COMMON.MONTHS')}`;
    }
    return `${years} ${years === 1 ? t('COMMON.YEAR') : t('COMMON.YEARS')}`;
  }
  return `${months} ${months === 1 ? t('COMMON.MONTH') : t('COMMON.MONTHS')}`;
};

export const CivilStatus = {
  SINGLE: 'SINGLE',
  MARRIED: 'MARRIED',
  DIVORCED: 'DIVORCED',
  WIDOWER: 'WIDOWER',
  FREE_UNION: 'FREE_UNION'
};
