import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Link,
  TextField,
  Typography
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getPersonTypeByRFC } from 'src/utils/common';
import theme from 'src/theme';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepOne = ({ data, handleNext }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { t } = useTranslation();

  const onNext = useCallback(
    async values => {
      const personType = getPersonTypeByRFC(values.rfc);
      handleNext({ ...values, personType });
    },
    [handleNext, data]
  );

  return (
    <Box
      sx={{
        height: '100%',
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '10px',
            color: theme.palette.primary.main
          }}
        >
          {t('BUREAU_FORM.STEP_ONE.TITLE')}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px'
          }}
        >
          {t('BUREAU_FORM.STEP_ONE.DESCRIPTION')}
        </Typography>
      </Box>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          handleChange,
          handleBlur
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                <Typography
                  variant="body1"
                  className={classes.subtitle}
                  sx={{
                    fontzSize: '10px',
                    color: 'text.secondary'
                  }}
                >
                  {t('BUREAU_FORM.STEP_ONE.APROXIMATE_TIME')}
                </Typography>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.rfc && errors.rfc)}
                  fullWidth
                  helperText={touched.rfc && t(errors.rfc)}
                  label={t('COMMON.RFC')}
                  margin="normal"
                  name="rfc"
                  onBlur={handleBlur}
                  onChange={e => {
                    handleChange(e);
                  }}
                  onInput={e => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                  type="text"
                  value={values.rfc}
                  variant="outlined"
                />

                <FormControl
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Checkbox onClick={() => setAcceptedTerms(!acceptedTerms)} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        margin: '0 5px'
                      }}
                    >
                      {t('COMMON.ACCEPT')}
                    </Typography>
                    <Link
                      href="https://www.astrocap.com/terminos-y-condiciones"
                      variant="a"
                      target="_blank"
                      sx={{
                        display: 'inline-block'
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          display: 'inline'
                        }}
                      >
                        {t('LOGIN_VIEW.TERMS_AND_CONDITIONS')}
                      </Typography>
                    </Link>
                    <Typography
                      variant="body1"
                      sx={{
                        margin: '0 5px'
                      }}
                    >
                      y
                    </Typography>
                    <Link
                      href="https://www.astrocap.com/privacidad"
                      variant="a"
                      target="_blank"
                      sx={{
                        display: 'inline-block'
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          display: 'inline'
                        }}
                      >
                        {t('LOGIN_VIEW.PRIVACY_POLICY')}
                      </Typography>
                    </Link>
                  </Box>
                </FormControl>
              </Box>

              <Box
                className={classes.box}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={clsx(
                    classes.buttons,
                    acceptedTerms && classes.buttonMain
                  )}
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disabled={!acceptedTerms}
                >
                  {t('COMMON.NEXT')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func
};

export default StepOne;
