import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import {
  deleteBankAction,
  editBankAction
} from 'src/store/actions/catalogActions';
import { useDispatch } from 'react-redux';
import BankForm from './BanksForm';

const BanksRow = ({ id, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openBankForm, setOpenBankForm] = useState(false);
  const [openBankDelete, setOpenBankDelete] = useState(false);

  const handleCloseDeleteModal = () => {
    setOpenBankDelete(false);
  };

  const handleCloseModal = () => {
    setOpenBankForm(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenBankForm(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenBankDelete(true);
    }
  };

  const handleDeleteModal = useCallback(() => {
    dispatch(deleteBankAction(id, handleCloseDeleteModal));
  }, [id]);

  const handleEdit = useCallback(
    values => {
      dispatch(editBankAction(values, handleCloseModal));
    },
    [id]
  );

  return (
    <TableRow hover>
      <TableCell align="start">{name}</TableCell>
      <TableCell align="right">
        <MoreMenu handleClickOption={handleClickMore} />
      </TableCell>

      <BankForm
        data={{ name, id }}
        open={openBankForm}
        onClose={handleCloseModal}
        onEdit={handleEdit}
      />
      <ConfirmationDialog
        open={openBankDelete}
        handleClose={handleCloseDeleteModal}
        title={t('CATALOGS_VIEW.BANKS.DELETE_BANK_CONFIRMATION_TITLE')}
        description={t(
          'CATALOGS_VIEW.BANKS.DELETE_BANK_CONFIRMATION_DESCRIPTION'
        )}
        onSubmit={handleDeleteModal}
      />
    </TableRow>
  );
};

BanksRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string
};

export default BanksRow;
