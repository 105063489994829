import React, { useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import dateFormatType from 'src/utils/dateFormatType';
import selectFormatDate from 'src/utils/selectDateFormat';
import { getClientFullName } from 'src/utils/common';
import {
  formatAmountWithDecimals,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { selectQuotesQueryOptionsStatus } from 'src/store/selectors/quoteSelectors';
import OverflowTipography from 'src/components/OverflowTipography';
import moment from 'moment';
import { archiveQuote, assignKamToQuote } from 'src/store/actions/quoteActions';
import { getDistributorProfileAssignedToClient } from 'src/store/actions/distributorActions';
import {
  QuoteActions,
  getBrandModelName,
  getUserQuotesMenuOptions
} from 'src/utils/quotes';
import AssignKamDialog from 'src/views/quotes/AssignKamDialog';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import DocumentViewerDialog from 'src/components/DocumentViewer/DocumentViewerDialog';
import { QuoteStatus } from 'src/utils/constants/quotes';
import Tooltip from 'src/components/Tooltip';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import ContractRequestForm from './ContractRequestForm';

const AdminQuotesRow = ({
  id,
  brand,
  createdAt,
  updatedAt,
  distributor,
  distributorProfile,
  model,
  price,
  client,
  document,
  term,
  initialPayment,
  isDirectorView = false,
  iva,
  priceWithIva,
  equipmentInsuranceIncluded,
  sentForClientApproval,
  clientData
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openAssignKamDialog, setOpenAssignKamDialog] = useState(false);
  const [openRequestContractDialog, setOpenRequestContractDialog] = useState(
    false
  );
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openDocumentViewer, setOpenDocumentViewer] = useState(false);

  const status = useSelector(selectQuotesQueryOptionsStatus);
  const daysSinceUpdated = moment().diff(moment(updatedAt), 'days') + 1;

  const [kamAssignedToClient, setKamAssignedToClient] = useState(null);

  const handleCloseRequestContractDialog = () => {
    setOpenRequestContractDialog(false);
  };

  const handleOpenArchiveDialog = () => {
    setOpenArchiveDialog(true);
  };

  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog(false);
  };

  const handleArchiveQuote = useCallback(() => {
    dispatch(archiveQuote(id, handleCloseArchiveDialog));
  }, [id]);

  const handleOpenAssignKamDialog = () => {
    dispatch(
      getDistributorProfileAssignedToClient(
        distributor.id,
        client.id,
        setKamAssignedToClient
      )
    );
    setOpenAssignKamDialog(true);
  };

  const handleCloseAssignKamDialog = () => {
    setOpenAssignKamDialog(false);
  };

  const handleSubmitAssignKam = useCallback(
    values => {
      dispatch(assignKamToQuote(id, values, handleCloseAssignKamDialog));
    },
    [id]
  );

  const handleClickMore = option => {
    if (option === QuoteActions.SEE_DOCUMENT) {
      setOpenDocumentViewer(true);
    }
    if (option === QuoteActions.ASSIGN_KAM) {
      handleOpenAssignKamDialog();
    }
    if (option === QuoteActions.CONTRACT_REQUEST) {
      setOpenRequestContractDialog(true);
    }
    if (option === QuoteActions.ARCHIVE) {
      handleOpenArchiveDialog();
    }
  };

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/quotes/detail/${id}`}>{`${id}`}</Link>
      </TableCell>
      <TableCell>
        <OverflowTipography maxWidth="100%">
          {selectFormatDate(createdAt, dateFormatType.DAY) ||
            t('COMMON.NO_DATA')}
        </OverflowTipography>
      </TableCell>

      <TableCell>
        <OverflowTipography>
          {client ? (
            <Link to={`/user/client/${client.id}`}>
              {getClientFullName(client)}
            </Link>
          ) : (
            <Tooltip condition text={clientData?.comment}>
              {clientData?.fullName ||
                t('ENUMS.QUOTE_STATUS.PENDING_ASSIGNMENT')}
            </Tooltip>
          )}
        </OverflowTipography>
      </TableCell>

      {isDirectorView && (
        <TableCell>
          <OverflowTipography maxWidth="80%">
            {distributorProfile ? (
              <Link to={`/user/kam/${distributorProfile.id}`}>
                {distributorProfile.name}
              </Link>
            ) : (
              t('COMMON.NO_DATA')
            )}
          </OverflowTipography>
        </TableCell>
      )}

      <TableCell align="center">
        <OverflowTipography>
          {getBrandModelName(brand, model)}
        </OverflowTipography>
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(price) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(iva) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(priceWithIva) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">{term ?? `${t('COMMON.NO_DATA')}`}</TableCell>
      <TableCell align="center">
        {initialPayment
          ? `${formatAmountWithDecimals(initialPayment)}%`
          : `${t('COMMON.NO_DATA')}`}
      </TableCell>

      <TableCell align="center">
        {t(
          `${daysSinceUpdated} ${
            daysSinceUpdated === 1 ? t('COMMON.DAY') : t('COMMON.DAYS')
          }`
        )}
      </TableCell>

      <TableCell align="center">
        {equipmentInsuranceIncluded
          ? t('COMMON.INCLUDED')
          : t('COMMON.NOT_INCLUDED')}
      </TableCell>

      {status === QuoteStatus.DRAFT && (
        <>
          <TableCell align="center">
            {sentForClientApproval ? (
              <Tooltip
                condition
                text={t('QUOTES_VIEW.SENT_FOR_CALIENT_APPROVAL')}
              >
                <StatusIcon type={IconStatusAndColor.OK.status} />
              </Tooltip>
            ) : (
              <Tooltip
                condition
                text={t('QUOTES_VIEW.NOT_SENT_FOR_CALIENT_APPROVAL')}
              >
                <StatusIcon type={IconStatusAndColor.ERROR.status} />
              </Tooltip>
            )}
          </TableCell>
        </>
      )}

      {/* Actons */}

      <TableCell
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
        align="right"
      >
        <MoreMenu
          deleteDisabled
          editDisabled
          options={getUserQuotesMenuOptions(document, status)}
          handleClickOption={handleClickMore}
        />
      </TableCell>

      <AssignKamDialog
        open={openAssignKamDialog}
        onClose={handleCloseAssignKamDialog}
        distributorId={distributor && distributor.id}
        onSubmit={handleSubmitAssignKam}
        kamAssignedToClient={kamAssignedToClient}
      />

      <ContractRequestForm
        open={openRequestContractDialog}
        handleClose={handleCloseRequestContractDialog}
        key="create-quote-form"
        price={price}
        quoteId={id}
        initialPayment={initialPayment}
        term={term}
        iva={iva}
      />

      <ConfirmationDialog
        open={openArchiveDialog}
        handleClose={handleCloseArchiveDialog}
        onSubmit={handleArchiveQuote}
        title={t('QUOTES_VIEW.ARCHIVE_QUOTE')}
        description={t('QUOTES_VIEW.ARCHIVE_QUOTE_DESCRIPTION')}
      />

      {openDocumentViewer && document.documentUrl && (
        <DocumentViewerDialog
          documentUrl={document.documentUrl}
          open={openDocumentViewer}
          onClose={() => setOpenDocumentViewer(false)}
        />
      )}
    </TableRow>
  );
};

export default AdminQuotesRow;

AdminQuotesRow.propTypes = {
  brand: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  distributor: PropTypes.object.isRequired,
  distributorProfile: PropTypes.object.isRequired,
  model: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  client: PropTypes.object.isRequired,
  document: PropTypes.object,
  id: PropTypes.number.isRequired,
  term: PropTypes.string,
  initialPayment: PropTypes.number,
  updatedAt: PropTypes.string.isRequired,
  isDirectorView: PropTypes.bool,
  iva: PropTypes.number,
  priceWithIva: PropTypes.number,
  equipmentInsuranceIncluded: PropTypes.bool,
  sentForClientApproval: PropTypes.bool,
  clientData: PropTypes.object
};
