import React, { useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TableCell from 'src/components/Table/TableCell';
import {
  TransactionActions,
  TransactionsCategory,
  TransactionsType
} from 'src/utils/constants/transactions';
import dateFormatType from 'src/utils/dateFormatType';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import OverflowTipography from 'src/components/OverflowTipography';
import TooltipTypography from 'src/components/TooltipTypography/TooltipTypography';
import { useDispatch } from 'react-redux';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import Assign from '@mui/icons-material/PublishedWithChanges';
import DocumentViewerDialog from 'src/components/DocumentViewer/DocumentViewerDialog';
import { assignTransactionToContract } from 'src/store/actions/transactionActions';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import AssignTransactionToContractDialog from './AssignTransactionToContractDialog';

const getTransactionOptions = (document, equipmentId) => {
  return [
    {
      name: TransactionActions.SEE_DOCUMENT,
      label: `ENUMS.QUOTE_ACTIONS.SEE_DOCUMENT`,
      disabled: !document,
      icon: <FileOpenIcon />
    },
    {
      name: TransactionActions.ASSIGN_TRANSACTION_TO_CONTRACT,
      label: `TRANSACTION.ASSOCIATE_TRANSACTION_TO_CONTRACT`,
      disabled: equipmentId,
      icon: <Assign />
    }
  ];
};

const AccountStatementRow = ({
  type,
  origin,
  category,
  amountIvaIncluded,
  comment,
  date,
  contractId,
  contractFolio,
  document,
  id,
  clientId,
  equipmentId,
  folio,
  invoiceUUID
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDocumentViewer, setOpenDocumentViewer] = useState(false);
  const [
    openAssignTransactionToContractDialog,
    setOpenAssignTransactionToContractDialog
  ] = useState(false);

  const handleCloseDocumentViewer = () => {
    setOpenDocumentViewer(false);
  };

  const handleCloseAssignInvoiceToContractDialog = () => {
    setOpenAssignTransactionToContractDialog(false);
  };

  const handleClickMore = option => {
    if (option === TransactionActions.SEE_DOCUMENT) {
      setOpenDocumentViewer(true);
    }
    if (option === TransactionActions.ASSIGN_TRANSACTION_TO_CONTRACT) {
      setOpenAssignTransactionToContractDialog(true);
    }
  };

  const handleAssignInvoiceToContract = useCallback(
    async values => {
      dispatch(
        assignTransactionToContract(
          id,
          values,
          handleCloseAssignInvoiceToContractDialog
        )
      );
    },
    [id]
  );
  return (
    <TableRow hover>
      <TableCell align="center">
        <OverflowTipography>
          {selectFormatDate(date, dateFormatType.DAY)}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <OverflowTipography>{moment(date).format('HH:mm')}</OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <OverflowTipography>
          {t(`TRANSACTION.CATEGORY.${category}`)}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        {category === TransactionsCategory.DISCOUNT &&
        type === TransactionsType.PAYMENT ? (
          <OverflowTipography>
            {t('TRANSACTION.BILLING_TYPE.CREDIT_NOTE')}
          </OverflowTipography>
        ) : (
          <OverflowTipography>
            {t(`TRANSACTION.BILLING_TYPE.${type}`)}
          </OverflowTipography>
        )}
      </TableCell>
      <TableCell align="center">
        <OverflowTipography>
          {t(`TRANSACTION.ORIGIN.${origin}`)}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        {contractId ? (
          <Link to={`/contracts/${contractId}`}>
            <OverflowTipography>{contractFolio}</OverflowTipography>
          </Link>
        ) : (
          <TooltipTypography
            title={t('TRANSACTION.NO_CONTRACT_ASSOCIATED')}
            text="--"
          />
        )}
      </TableCell>
      <TableCell align="center">
        {folio ? (
          <OverflowTipography maxWidth="300px">{folio}</OverflowTipography>
        ) : (
          <TooltipTypography
            title={t('TRANSACTION.NO_INVOICE_ASSOCIATED')}
            text="--"
          />
        )}
      </TableCell>
      <TableCell align="center">
        <OverflowTipography>{comment || '--'}</OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <OverflowTipography>
          {type === TransactionsType.PAYMENT
            ? '--'
            : formatAmountWithDecimals(amountIvaIncluded, 2)}
        </OverflowTipography>
      </TableCell>
      <TableCell align="center">
        <OverflowTipography>
          {type === TransactionsType.PAYMENT
            ? formatAmountWithDecimals(amountIvaIncluded, 2)
            : '--'}
        </OverflowTipography>
      </TableCell>
      <TableCell>
        <MoreMenu
          deleteDisabled
          editDisabled
          options={getTransactionOptions(document, equipmentId)}
          handleClickOption={handleClickMore}
        />
      </TableCell>

      {openDocumentViewer && document.documentUrl && (
        <DocumentViewerDialog
          documentUrl={document.documentUrl}
          open={openDocumentViewer}
          onClose={handleCloseDocumentViewer}
          fileName={`${invoiceUUID}`}
        />
      )}
      {openAssignTransactionToContractDialog && (
        <AssignTransactionToContractDialog
          handleAssignInvoiceToContract={handleAssignInvoiceToContract}
          open={openAssignTransactionToContractDialog}
          transaction={{ id, clientId, equipmentId }}
          handleClose={handleCloseAssignInvoiceToContractDialog}
        />
      )}
    </TableRow>
  );
};

AccountStatementRow.propTypes = {
  type: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  amountIvaIncluded: PropTypes.number.isRequired,
  comment: PropTypes.string,
  date: PropTypes.string.isRequired,
  contractId: PropTypes.string,
  contractFolio: PropTypes.string,
  document: PropTypes.object,
  id: PropTypes.string,
  clientId: PropTypes.string,
  equipmentId: PropTypes.string,
  folio: PropTypes.string,
  invoiceUUID: PropTypes.string
};

export default AccountStatementRow;
