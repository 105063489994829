import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from '@date-io/moment';
import { ToastContainer } from 'react-toastify';
import GlobalStyles from 'src/components/GlobalStyles';
import moment from 'moment';
import theme from 'src/theme';
import store from 'src/store';
import AppRoutes from 'src/routes/AppRoutes';
import SnackbarMessage from './components/Snackbar/SnackbarMessage';
import Preloader from './components/Preloader';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import RedirectToMobile from './components/RedirectToMobile';
import { getLanguage } from './utils/localStorage';

const App = () => {
  const [locale, setLocale] = useState(moment.locale());

  useEffect(() => {
    setLocale(getLanguage());
  }, []);

  return (
    <Suspense fallback={<Preloader />}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <LocalizationProvider dateAdapter={MomentUtils} localeText={locale}>
              <AppRoutes />
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                style={{ fontSize: '1rem' }}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                progressStyle={{ background: '#153750' }}
              />
              <RedirectToMobile />
            </LocalizationProvider>
            <SnackbarMessage />
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
