import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, IconButton, Box } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { clientDetailTabsIndex } from 'src/utils/constants/clients';
import TabPanel from 'src/components/TabPanel/TabPanel';
import UserType from 'src/utils/constants/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import { loadUserClientDetail } from 'src/store/actions/userClientActions';
import { loadClientDetail, loadClientFullName } from 'src/store/actions/clientActions';
import { selectClientDetail } from 'src/store/selectors/clientSelectors';
import Breadcrumb from 'src/components/Breadcrumb';
import GeneralView from './General';
import AvalView from './AvalDetail';
import AccountStatementDetail from './AccountStatementDetail';
import ContractsDetail from './ContractsList';
import AdministrativesList from './AdministrativesDetail';
import NuffiView from './NuffiDetail';
import MoffinView from './BuroValidationDetail';
import SatWsDetailView from './SatSwDetail/SatWsDetailView';
import QuotesDetail from './QuotesDetail';

const GeneralMenuList = [
  {
    value: clientDetailTabsIndex.GENERAL,
    label: 'COMMON.GENERAL'
  },
  {
    value: clientDetailTabsIndex.AVAL_DETAIL,
    label: 'COMMON.AVALS'
  },
  {
    value: clientDetailTabsIndex.ADMINISTRATIVE_DETAIL,
    label: 'COMMON.CONTACTS'
  },
  {
    value: clientDetailTabsIndex.ACCOUNTING_STATE,
    label: 'NAVIGATION.ACCOUNTING_STATE'
  },
  {
    value: clientDetailTabsIndex.CONTRACTS_DETAIL,
    label: 'COMMON.CONTRACTS'
  },
  {
    value: clientDetailTabsIndex.QUOTES_DETAIL,
    label: 'COMMON.QUOTES'
  },
  {
    value: clientDetailTabsIndex.NUFI_VALIDATION,
    label: 'CLIENTS_VIEW.CLIENT_DETAIL.IDENTITY'
  },
  {
    value: clientDetailTabsIndex.MOFFIN_VALIDATION,
    label: 'CLIENTS_VIEW.CLIENT_DETAIL.MOFFIN.TITLE'
  },
  {
    value: clientDetailTabsIndex.SAT_WS_VALIDATION,
    label: 'NAVIGATION.BILLING'
  }
];

const ClientTabs = () => {
  const { goBack, push } = useHistory();
  const { t } = useTranslation();
  const { id, tab } = useParams();
  const system = useSelector(selectAuthSystem);
  const [value, setValue] = useState(false);
  const dispatch = useDispatch();
  const client = useSelector(selectClientDetail);
  const [clientFullName, setClientFullName] = useState('');

  const selectUserData = () => {
    if (system === UserType.DISTRIBUTOR) {
      return dispatch(loadUserClientDetail(id));
    }
    return dispatch(loadClientDetail(id));
  };

  useEffect(() => {
    selectUserData();
    dispatch(loadClientFullName(id, setClientFullName));
  }, [dispatch, id]);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/clients/detail/${id}/${newValue}`);
    },
    [setValue]
  );

  useEffect(() => {
    if (id && !tab) {
      setValue(clientDetailTabsIndex.GENERAL);
    }
    if (tab) {
      if (Object.values(clientDetailTabsIndex).includes(tab)) {
        setValue(tab);
      } else {
        push(`/clients/detail/${id}`);
      }
    }
  }, [id, tab]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb
        doNotRedirectIndexList={[1]}
        stringCrumb={clientFullName}
      />
      <AppBar
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          {GeneralMenuList.map(item => (
            <Tab key={item.value} label={t(item.label)} value={item.value} />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={clientDetailTabsIndex.GENERAL}>
        <GeneralView client={client} />
      </TabPanel>
      <TabPanel value={value} index={clientDetailTabsIndex.AVAL_DETAIL}>
        <AvalView />
      </TabPanel>
      <TabPanel
        value={value}
        index={clientDetailTabsIndex.ADMINISTRATIVE_DETAIL}
      >
        <AdministrativesList />
      </TabPanel>
      <TabPanel value={value} index={clientDetailTabsIndex.ACCOUNTING_STATE}>
        <AccountStatementDetail />
      </TabPanel>
      <TabPanel value={value} index={clientDetailTabsIndex.CONTRACTS_DETAIL}>
        <ContractsDetail />
      </TabPanel>
      <TabPanel value={value} index={clientDetailTabsIndex.NUFI_VALIDATION}>
        <NuffiView />
      </TabPanel>
      <TabPanel value={value} index={clientDetailTabsIndex.MOFFIN_VALIDATION}>
        <MoffinView />
      </TabPanel>
      <TabPanel value={value} index={clientDetailTabsIndex.SAT_WS_VALIDATION}>
        <SatWsDetailView />
      </TabPanel>
      <TabPanel value={value} index={clientDetailTabsIndex.QUOTES_DETAIL}>
        <QuotesDetail />
      </TabPanel>
    </Box>
  );
};

export default ClientTabs;
