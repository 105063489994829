import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
  Grid,
  FormControl,
  TextField,
  Switch
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import clsx from 'clsx';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvestorList } from 'src/store/selectors/investorSelector';
import {
  CREATE_INVESTOR,
  LOAD_INVESTORS,
  loadInvestors
} from 'src/store/actions/investorActions';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_INVESTMENT } from 'src/store/actions/investmentActions';
import Preloader from 'src/components/Preloader';
import moment from 'moment';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from './styles';
import InvestmentAlert from './InvestmentAlert';

const CreateInvestmentForm = ({ open, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(), []);
  const investors = useSelector(selectInvestorList);
  const isLoadingInvestors = useSelector(
    isLoadingSelector([LOAD_INVESTORS.action])
  );
  const isCreatingInvestment = useSelector(
    isLoadingSelector([CREATE_INVESTMENT.action])
  );
  const resetRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOuterSubmit = useCallback(
    values => {
      submit(values);
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadInvestors({ page: 0, status: null }, false));
      dispatch({ type: CREATE_INVESTOR });
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('COMMON.CREATE_INVESTMENT')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
            handleBlur,
            handleChange
          }) => {
            resetRef.current = () => resetForm();
            return (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="stretch">
                  {!isCreatingInvestment ? (
                    <>
                      <InvestmentAlert
                        investors={investors}
                        loading={isLoadingInvestors}
                      />
                      <FormControl fullWidth>
                        <Autocomplete
                          options={investors}
                          id="investor"
                          getOptionLabel={option => {
                            return option ? option.fullName : '';
                          }}
                          value={investors.find(
                            investor => investor.id === values.investor?.id
                          )}
                          isLoading={isLoadingInvestors}
                          keepMounted
                          fullWidth
                          sx={{ marginBottom: 1, marginTop: 2 }}
                          onChange={(_e, value) => {
                            setFieldValue('investor', value || '');
                          }}
                          helperText={touched.investor && t(errors.investor)}
                          label={t('COMMON.INVESTOR')}
                          error={Boolean(touched.investor && errors.investor)}
                        />
                      </FormControl>

                      <TextField
                        InputProps={{
                          autoComplete: 'off',
                          inputComponent: MoneyMask
                        }}
                        error={Boolean(touched.amount && errors.amount)}
                        fullWidth
                        helperText={touched.amount && t(errors.amount)}
                        label={t('COMMON.AMOUNT')}
                        margin="normal"
                        name="amount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.amount}
                        variant="outlined"
                      />

                      {values.skipPaymentFlow && (
                        <DatePicker
                          label={t('COMMON.DATE')}
                          value={values.date}
                          margin="date"
                          inputFormat="DD/MM/YYYY"
                          onChange={newValue => {
                            setFieldValue(
                              'date',
                              newValue ? moment(newValue) : ''
                            );
                          }}
                          error={Boolean(touched.date && errors.date)}
                          renderInput={params => (
                            <TextField
                              InputProps={{
                                autoComplete: 'off'
                              }}
                              {...params}
                              sx={{ width: '100%', margin: '16px 0' }}
                              FormHelperTextProps={{ sx: { color: 'red' } }}
                              helperText={touched.date && t(errors.date)}
                            />
                          )}
                        />
                      )}

                      <FormControl>
                        <Typography>
                          {t('INVESTMENTS_VIEW.CREATE_EXISTING_INVESTMENT')}
                        </Typography>
                        <Switch
                          checked={values.skipPaymentFlow}
                          onChange={(_e, value) => {
                            setFieldValue('skipPaymentFlow', value);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />

                        {values.skipPaymentFlow && (
                          <Typography
                            sx={{
                              color: '#ce0505',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              marginTop: '10px'
                            }}
                          >
                            {`*${t(
                              'INVESTMENTS_VIEW.CREATE_EXISTING_INVESTMENT_WARNING'
                            )}*`}
                          </Typography>
                        )}
                      </FormControl>

                      <Grid item>
                        <Button
                          className={clsx(classes.label, classes.buttons)}
                          type="submit"
                          color="primary"
                          variant="outlined"
                          fullWidth
                        >
                          {t('COMMON.CREATE')}
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Preloader />
                      <Typography variant="h3" sx={{ textAlign: 'center' }}>
                        {t('COMMON.CREATING_INVESTMENT')}
                      </Typography>
                    </>
                  )}
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CreateInvestmentForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default CreateInvestmentForm;
