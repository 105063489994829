/* eslint-disable func-names */
import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'src/utils/common';
import * as Yup from 'yup';

export default Yup.object().shape({
  fullName: Yup.string().required('SCHEMA_ERRORS.FULL_NAME_REQUIRED'),
  email: Yup.string()
    .nullable()
    .test('email', 'SCHEMA_ERRORS.EMAIL_INVALID', function(value) {
      return !value || validateEmail(value);
    }),
  phone: Yup.string()
    .required('SCHEMA_ERRORS.PHONE_REQUIRED')
    .test('phone', 'SCHEMA_ERRORS.PHONE_INVALID', function(value) {
      return !value || isValidPhoneNumber(`+${value}`);
    }),
  comment: Yup.string()
});

export const validateEmailOrPhoneExists = values => {
  const errors = {};
  if (!values.email && !values.phone) {
    errors.email = 'SCHEMA_ERRORS.EMAIL_OR_PHONE_REQUIRED';
    errors.phone = 'SCHEMA_ERRORS.EMAIL_OR_PHONE_REQUIRED';
  }
  return errors;
};
