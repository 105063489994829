import moment from 'moment';

const makeInitialState = values => {
  return {
    id: values.id ?? '',
    term: values.term ?? '',
    moreInfo: values.moreInfo ?? '',
    price: values.price ?? '',
    initialPaymentAmount: values.price * (values.initialPayment / 100) ?? '',
    initialPaymentDate: values.initialPaymentDate ?? moment(),
    firstRentDate: values.firstRentDate ?? moment(),
    iva: values.iva ?? '',
    gracePeriod: values.gracePeriod ?? ''
  };
};

export default makeInitialState;
