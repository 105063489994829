/* eslint-disable react/prop-types */
import React from 'react';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import ParameterListView from 'src/views/parameter/ParameterListView';

const ParameterView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}`} component={ParameterListView} />
    </>
  );
};

export default ParameterView;
