import { PersonType } from './clients';

export const AccountType = {
  PF: 'PF',
  PM: 'PM'
};

export const selectPersonType = personType => {
  switch (personType) {
    case PersonType.PHYSICAL:
      return AccountType.PF;
    case PersonType.LEGAL:
      return AccountType.PM;
    default:
      return '';
  }
};

export const transformFormData = values => {
  return {
    rfc: values.rfc,
    curp: values.curp,
    address: values.street,
    interiorNumber: values.insideHomeNumber,
    exteriorNumber: values.externalHomeNumber,
    neighborhood: values.colonia,
    city: values.city,
    state: values.state,
    zipCode: values.zipCode,
    nss: values.identificationNumber,
    accountType: selectPersonType(values.personType),
    name: values.name,
    paternalName: values.paternalName,
    maternalName: values.maternalName,
    phone: values.phone,
    email: values.email,
    sex: values.sex,
    tradeName: values.denomination,
    nationality: 'MX',
    country: 'MX'
  };
};
