import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  CREATE_BURO_FORM,
  createBureauForm
} from 'src/store/actions/bureauFormActions';
import Loading from 'src/components/Loading';
import useStyles from '../styles';
import ReviewData from './ReviewData';

const StepFour = ({ data, handleNext, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoadingCreateForm = useSelector(
    isLoadingSelector([CREATE_BURO_FORM.action])
  );

  const onNext = useCallback(async () => {
    dispatch(createBureauForm({ ...data }, handleNext));
  }, [handleNext, data]);

  return (
    <Box
      sx={{
        height: '100%',
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {isLoadingCreateForm ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="h3"
            sx={{
              marginBottom: '10px',
              color: theme.palette.primary.main
            }}
          >
            {t('BUREAU_FORM.REVIEW_DATA.TITLE')}
          </Typography>
          <Typography
            sx={{
              marginBottom: '20px',
              fontSize: '14px'
            }}
            variant="body1"
          >
            {t('BUREAU_FORM.REVIEW_DATA.DESCRIPTION')}
          </Typography>
          <ReviewData values={data} />
        </Box>
      )}
      <Box
        className={classes.box}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          className={classes.buttons}
          onClick={() => handleBack()}
          color="primary"
          variant="outlined"
          disabled={isLoadingCreateForm}
        >
          {t('COMMON.BACK')}
        </Button>
        <Button
          className={clsx(classes.buttons, classes.buttonMain)}
          onClick={onNext}
          color="primary"
          variant="outlined"
          disabled={isLoadingCreateForm}
        >
          {t('COMMON.NEXT')}
        </Button>
      </Box>
    </Box>
  );
};
StepFour.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func
};

export default StepFour;
