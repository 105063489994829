import {
  CLEAR_CATALOG,
  LOAD_CATALOG,
  LOAD_INSURERS,
  LOAD_AGENTS,
  LOAD_EXPENSE_TYPES,
  LOAD_BRANDS,
  LOAD_TAX_SYSTEM,
  LOAD_EQUIPMENT_TYPES,
  LOAD_BILLING_CONCEPT,
  LOAD_MODELS,
  SET_MODEL_NAME,
  LOAD_KINSHIPS,
  LOAD_INDUSRTIES,
  LOAD_ADMINISTRATIVE_ROLES,
  LOAD_DENOMINATIONS,
  SET_DISTRIBUTOR_ID,
  CLEAR_DISTRIBUTOR_ID,
  LOAD_ECONOMIC_ACTIVITIES
} from '../actions/catalogActions';
import { LOAD_QUOTE_CATEGORIES } from '../actions/quoteActions';

const initialState = {
  items: [],
  insurers: [],
  agents: [],
  expenseTypes: [],
  models: {
    items: [],
    name: ''
  },
  kinships: {
    items: []
  },
  economicActivities: {
    items: []
  },
  industries: {
    items: []
  },
  denominations: {
    items: []
  },
  administrativeRoles: {
    items: []
  },
  distributorId: null
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CATALOG.success: {
      return {
        ...state,
        items: action.data
      };
    }
    case LOAD_INSURERS.success: {
      return {
        ...state,
        insurers: action.data
      };
    }
    case LOAD_AGENTS.success: {
      return {
        ...state,
        agents: action.data
      };
    }
    case LOAD_EXPENSE_TYPES.success: {
      return {
        ...state,
        expenseTypes: action.data
      };
    }
    case LOAD_BRANDS.success: {
      return {
        ...state,
        items: action.data
      };
    }
    case LOAD_TAX_SYSTEM.success: {
      return {
        ...state,
        items: action.data
      };
    }
    case LOAD_ECONOMIC_ACTIVITIES.success: {
      return {
        ...state,
        items: action.data
      };
    }
    case LOAD_EQUIPMENT_TYPES.success: {
      return {
        ...state,
        items: action.data
      };
    }
    case LOAD_BILLING_CONCEPT.success: {
      return {
        ...state,
        items: action.data
      };
    }
    case LOAD_QUOTE_CATEGORIES.success: {
      return {
        ...state,
        items: action.data
      };
    }
    case LOAD_MODELS.success: {
      return {
        ...state,
        models: {
          ...state.models,
          items: action.data
        }
      };
    }
    case SET_MODEL_NAME: {
      return {
        ...state,
        models: {
          ...state.models,
          name: action.name
        }
      };
    }
    case LOAD_KINSHIPS.success: {
      return {
        ...state,
        kinships: {
          ...state.kinships,
          items: action.data
        }
      };
    }
    case LOAD_INDUSRTIES.success: {
      return {
        ...state,
        industries: {
          ...state.industries,
          items: action.data
        }
      };
    }
    case LOAD_DENOMINATIONS.success: {
      return {
        ...state,
        denominations: {
          ...state.denominations,
          items: action.data
        }
      };
    }
    case LOAD_ADMINISTRATIVE_ROLES.success: {
      return {
        ...state,
        administrativeRoles: {
          ...state.administrativeRoles,
          items: action.data
        }
      };
    }
    case SET_DISTRIBUTOR_ID: {
      return {
        ...state,
        distributorId: action.payload
      };
    }
    case CLEAR_DISTRIBUTOR_ID: {
      return {
        ...state,
        distributorId: null
      };
    }
    case CLEAR_CATALOG: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default catalogReducer;
